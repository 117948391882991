import axios, { AxiosResponse } from 'axios';
import { setupCache } from 'axios-cache-adapter';

import {
    PapersQuery,
    MapQuery,
    ComentionQuery,
    TermsQuery,
    CrossfilterPapersQuery,
    CrossfilterClustersQuery,
    ClusterDetailsQuery
} from './Query';
import {
    PapersAnswer,
    MapAnswer,
    ComentionAnswer,
    TermsAnswer,
    CrossfilterPapersAnswer,
    CrossfilterClustersAnswer,
    ClusterDetailsAnswer
} from './Models';

const cache = setupCache({
    // 5 min
    maxAge: 5 * 60 * 1000,
    // default is to skip cache if there are query params, this allows us to cache even with params
    exclude: { query: false }
});

export const api = axios.create();

export const apiWithCache = axios.create({
    adapter: cache.adapter
});

export function logCache<T>(resp: AxiosResponse<T>): AxiosResponse<T> {
    if (resp.request.fromCache) {
        console.log(
            `Serving cached response for ${resp.config.url} ${JSON.stringify(resp.config.params)}`
        );
    }
    return resp;
}

export function getPapers(query: PapersQuery): Promise<PapersAnswer> {
    return apiWithCache
        .post('/api/papers', query)
        .then(resp => logCache(resp))
        .then(resp => {
            return resp.data;
        });
}

export function getMaps(query: MapQuery): Promise<MapAnswer> {
    return apiWithCache
        .post('/api/map', query)
        .then(resp => logCache(resp))
        .then(resp => {
            return resp.data;
        });
}

export function getComentions(query: ComentionQuery): Promise<ComentionAnswer> {
    return apiWithCache
        .post('/api/comention', query)
        .then(resp => logCache(resp))
        .then(resp => {
            return resp.data;
        });
}

export function getTerms(query: TermsQuery): Promise<TermsAnswer> {
    return apiWithCache
        .post(`/api/terms`, query)
        .then(resp => logCache(resp))
        .then(resp => resp.data);
}

export function filterPaperData(query: CrossfilterPapersQuery): Promise<CrossfilterPapersAnswer> {
    return apiWithCache
        .post(`/napi/filter_paper_data`, query)
        .then(resp => logCache(resp))
        .then(resp => resp.data);
}

export function filterClusterData(
    query: CrossfilterClustersQuery
): Promise<CrossfilterClustersAnswer> {
    return apiWithCache
        .post(`/napi/filter_cluster_data`, query)
        .then(resp => logCache(resp))
        .then(resp => resp.data);
}

export function getClusterDetails(query: ClusterDetailsQuery): Promise<ClusterDetailsAnswer> {
    return apiWithCache
        .post('/napi/get_cluster_details', query)
        .then(resp => logCache(resp))
        .then(resp => {
            return resp.data;
        });
}
