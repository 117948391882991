import * as React from 'react';
import styled from 'styled-components';
import * as dc from 'dc';

interface Props {
    itemTypeName?: string;
    data: any;
    className?: string;
}

export class DataCount extends React.Component<Props> {
    render() {
        return <ResetAllButton {...this.props} />;
    }
}

const ResetAllButton = styled((props: Props) => {
    return (
        <>
            {props.data.selected !== props.data.all ? (
                <Wrapper>
                    Showing <strong>{props.data.selected}</strong> out of{' '}
                    <strong>{props.data.all}</strong>{' '}
                    {props.itemTypeName ? props.itemTypeName : 'Items'}.{/* eslint-disable */}
                    <a
                        className={`${props.className}`}
                        onClick={() => {
                            dc.filterAll();
                            dc.redrawAll();
                        }}>
                        <span>reset all</span>
                    </a>
                    {/* eslint-enable */}
                </Wrapper>
            ) : (
                <Wrapper>
                    Showing all <strong>{props.data.all}</strong>{' '}
                    {props.itemTypeName ? props.itemTypeName : 'Items'}. Click a chart below to
                    filter down.
                </Wrapper>
            )}
        </>
    );
})`
    span {
        padding: 5px;
        font-size: 11px;
        cursor: pointer;
        margin-left: 5px;
        border-radius: 7px;
        :hover {
            background: ${({ theme }) => theme.color.B2};
        }
    }
`;

const Wrapper = styled.span`
    font-size: ${({ theme }) => theme.typography.bodyBig.fontSize};
`;
