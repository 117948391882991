import * as React from 'react';

type ContextProps = {
    state: State;
    updateState: (updates: Partial<State>) => void;
};

export type StateContextType = Partial<ContextProps>;

export const StateContext = React.createContext<StateContextType>({});

interface Props {
    children: JSX.Element[] | JSX.Element;
}

interface Chart {
    [title: string]: any;
}

interface State {
    charts?: Chart;
    multiSelects?: Chart;
}

export class StateContextProvider extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            charts: {},
            multiSelects: {}
        };
    }

    updateState = (updates: Partial<State>) => {
        this.setState(updates);
    };

    render() {
        return (
            <StateContext.Provider value={{ state: this.state, updateState: this.updateState }}>
                <div>{this.props.children}</div>
            </StateContext.Provider>
        );
    }
}
