import * as React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import * as dc from 'dc';
import debounce from 'debounce';
import { Body, Select } from '@allenai/varnish/components';
import { below } from '@allenai/varnish/theme/breakpoints';
import { List, Collapse } from 'antd';

import {
    filterClusterData,
    ClusterDetailsQuery,
    ClusterDetailsAnswer,
    getClusterDetails,
    CrossfilterClustersQuery,
    CrossfilterClustersAnswer,
    CrossfilterClustersFilter,
    Paper
} from '../api';
import {
    MetaTags,
    RowChart,
    NodeNetwork,
    Loading,
    DataCount,
    Error,
    PaperSummary,
    MultiSelectChart,
    Filters,
    ChartWithFilter,
    ChartGroup,
    Last,
    Heading,
    MainWrapper,
    IntroText,
    Summary,
    SmallList,
    TryExamplesSimple
} from '../components';
import { StateContext, StateContextType } from '../StateContext';

enum View {
    EMPTY,
    LOADING,
    ANSWER,
    ERROR
}

interface Props extends RouteComponentProps {
    seed: CrossfilterClustersFilter;
}

interface State {
    initialized: boolean;
    forceStop?: boolean;

    maxClustersToShow: number;

    crossfilterQuery: CrossfilterClustersQuery;
    crossfilterView: View;
    crossfilterAnswer?: CrossfilterClustersAnswer;
    crossfilterError?: string;

    clusterDetailsQuery: ClusterDetailsQuery;
    clusterDetailsView: View;
    clusterDetailsAnswer?: ClusterDetailsAnswer;
    clusterDetailsError?: string;
}

export default class Clusters extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        const cq = CrossfilterClustersQuery.fromQueryString(props.location);
        if (!cq.filter || !cq.isValid()) {
            cq.filter = this.props.seed;
        }

        this.state = {
            initialized: false,
            maxClustersToShow: 10,
            crossfilterQuery: cq,
            crossfilterView: View.EMPTY,
            clusterDetailsQuery: new ClusterDetailsQuery(),
            clusterDetailsView: View.EMPTY
        };
    }

    componentDidMount() {
        this.fetchFilterClusterDebounced();
    }

    getDim = (id: string) => {
        return {
            filter: (f: any) => {
                const { crossfilterQuery } = this.state;
                if (!crossfilterQuery.filter) {
                    crossfilterQuery.filter = {};
                }
                crossfilterQuery.filter[id] = f;

                this.setState({ crossfilterQuery, forceStop: true }, () => {
                    this.fetchFilterClusterDebounced();
                });
            },
            filterAll: () => {}
        };
    };

    getGroup = (id: string) => {
        return {
            all: () => {
                if (
                    this.state.crossfilterView === View.ANSWER &&
                    this.state.crossfilterAnswer &&
                    this.state.crossfilterAnswer.answer &&
                    this.state.crossfilterAnswer.answer.filteredData[id]
                ) {
                    return this.state.crossfilterAnswer.answer.filteredData[id].values;
                }
                return [];
            },
            order: () => {},
            top: () => {}
        };
    };

    getInitialRowFilter = (id: string) => {
        return this.state.crossfilterQuery.filter && this.state.crossfilterQuery.filter[id]
            ? this.state.crossfilterQuery.filter[id]
            : undefined;
    };

    fetchFilterClusterData() {
        this.props.history.push(`/Clusters?${this.state.crossfilterQuery.toQueryString()}`);
        const originalCrossfilterQuery = this.state.crossfilterQuery;
        this.setState({ crossfilterView: View.LOADING }, () => {
            filterClusterData(this.state.crossfilterQuery)
                .then(answer => {
                    if (this.state.crossfilterQuery.equals(originalCrossfilterQuery)) {
                        console.log(answer); // todo: remove
                        this.setState(
                            {
                                forceStop: false,
                                initialized: true,
                                crossfilterView: View.ANSWER,
                                crossfilterError: undefined,
                                crossfilterAnswer: answer
                            },
                            () => {
                                dc.renderAll(); // render charts
                            }
                        );
                    }
                })
                .catch((err: any) => {
                    if (this.state.crossfilterQuery.equals(originalCrossfilterQuery)) {
                        let error;
                        if (err.response) {
                            if (err.response.data && err.response.data.error) {
                                error = err.response.data.error;
                            } else if (err.response.status === 503) {
                                error =
                                    'Our system is a little overloaded, ' +
                                    'please try again in a moment';
                            }
                        }
                        // Fallback to a general error message
                        if (!error) {
                            error = 'Something went wrong. Please try again.';
                        }
                        this.setState({
                            forceStop: false,
                            crossfilterView: View.ERROR,
                            crossfilterAnswer: undefined,
                            crossfilterError: error
                        });
                    }
                });
        });
    }

    fetchFilterClusterDebounced = debounce(this.fetchFilterClusterData, 300);

    fetchClusterDetails() {
        const originalClusterDetailsQuery = this.state.clusterDetailsQuery;
        this.setState({ clusterDetailsView: View.LOADING }, () => {
            getClusterDetails(this.state.clusterDetailsQuery)
                .then(answer => {
                    console.log(answer); // todo: remove
                    if (this.state.clusterDetailsQuery.equals(originalClusterDetailsQuery)) {
                        this.setState({
                            clusterDetailsView: View.ANSWER,
                            clusterDetailsError: undefined,
                            clusterDetailsAnswer: answer
                        });
                    }
                })
                .catch((err: any) => {
                    if (this.state.clusterDetailsQuery.equals(originalClusterDetailsQuery)) {
                        let error;
                        if (err.response) {
                            if (err.response.data && err.response.data.error) {
                                error = err.response.data.error;
                            } else if (err.response.status === 503) {
                                error =
                                    'Our system is a little overloaded, ' +
                                    'please try again in a moment';
                            }
                        }
                        // Fallback to a general error message
                        if (!error) {
                            error = 'Something went wrong. Please try again.';
                        }
                        this.setState({
                            clusterDetailsView: View.ERROR,
                            clusterDetailsAnswer: undefined,
                            clusterDetailsError: error
                        });
                    }
                });
        });
    }

    handelClusterClick = (clusterId: string) => {
        const clusterDetailsQuery = new ClusterDetailsQuery(clusterId);
        this.setState({ clusterDetailsQuery }, () => {
            this.fetchClusterDetails();
        });
    };

    render() {
        return (
            <StateContext.Consumer>
                {stateContext => {
                    return (
                        <MainWrapper>
                            <MetaTags
                                title="CORD-19 Network of Science"
                                description="Explore the progress being made against COVID-19, with a visualization of research groups and their ties"
                            />
                            <IntroText as="div">
                                <h4>
                                    Explore the network of scientists working on{' '}
                                    <a
                                        href="https://pages.semanticscholar.org/coronavirus-research"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        COVID-19
                                    </a>{' '}
                                    with a visualization of research groups and their ties
                                </h4>
                                <p>
                                    <strong>
                                        1. Find groups by searching topics, affiliations or authors
                                    </strong>
                                    <br />
                                    <Body>
                                        Groups are mined from the co-authorship network (with papers
                                        from 2017-present).
                                    </Body>
                                    <br />
                                    <strong>
                                        2. Each "box" shows salient authors, affilations and topics
                                        in a group
                                    </strong>
                                    <br />
                                    <Body>
                                        Select how many groups to view, zoom in/out, click a group
                                        and scroll down to see papers.
                                    </Body>
                                    <br />
                                    <strong>
                                        3. Links between groups represent what top authors and
                                        topics they have in common
                                    </strong>
                                    <br />
                                    <Body>
                                        Hover over green links to see shared authors, and purple
                                        links to see topics
                                    </Body>
                                </p>
                            </IntroText>
                            {this.state.initialized ? (
                                <>
                                    <TryExamplesSimple
                                        examples={[
                                            {
                                                label: 'Topic: Cytokine storm',
                                                filter: '{"topicsDimension":["Cytokine storm"]}'
                                            },
                                            {
                                                label: 'Topic: Chloroquine',
                                                filter: '{"topicsDimension":["Chloroquine"]}'
                                            },
                                            {
                                                label: 'Affiliation: Oxford University',
                                                filter:
                                                    '{"affiliationsDimension":["Uni. of Oxford"]}'
                                            },

                                            {
                                                label:
                                                    'Topic: Social distance and Affiliation: CDC',
                                                filter:
                                                    '{"topicsDimension":["Social distance"],"affiliationsDimension":["Centers for Disease Control and Prevention"]}'
                                            },
                                            {
                                                label: 'Author: Ian A. Wilson',
                                                filter:
                                                    '{"authorsDimension":["Ian A. Wilson::2115778272"]}'
                                            },
                                            {
                                                label: 'Affiliation: Chinese Academy of Sciences',
                                                filter:
                                                    '{"affiliationsDimension":["Chinese Academy of Sciences"]}'
                                            },
                                            {
                                                label:
                                                    'Author: (Ian A. Wilson or Antonio Lanzavecchia)',
                                                filter:
                                                    '{"authorsDimension":["Ian A. Wilson::2115778272","Antonio Lanzavecchia::724449561"]}'
                                            },
                                            {
                                                label:
                                                    'Topic: Viral protein and Affiliation: UPenn',
                                                filter:
                                                    '{"topicsDimension":["Viral protein"],"affiliationsDimension":["Uni. of Pennsylvania"]}'
                                            },
                                            {
                                                label:
                                                    'Topic: Ebola and Affiliation: Chinese Academy of Sciences',
                                                filter:
                                                    '{"topicsDimension":["Ebola"],"affiliationsDimension":["Chinese Academy of Sciences"]}'
                                            }
                                        ]}
                                        queryUrl={`/clusters?filter=`}
                                        maxLinks={4}
                                    />
                                    <Filters>
                                        <ThreeColChartGroup>
                                            <ChartWithFilter>
                                                <this.SimpleRowChart title="Topics" id="topics" />
                                                <this.SimpleMultiSelectChart
                                                    label="Select Topics"
                                                    id="topics"
                                                    stateContext={stateContext}
                                                />
                                            </ChartWithFilter>
                                        </ThreeColChartGroup>
                                        <ThreeColChartGroup>
                                            <ChartWithFilter>
                                                <this.SimpleRowChart
                                                    title="Affiliation"
                                                    id="affiliations"
                                                />
                                                <this.SimpleMultiSelectChart
                                                    label="Select Affiliations"
                                                    id="affiliations"
                                                    stateContext={stateContext}
                                                />
                                            </ChartWithFilter>
                                        </ThreeColChartGroup>
                                        <ThreeColChartGroup>
                                            <ChartWithFilter>
                                                <this.SimpleRowChart title="Authors" id="authors" />
                                                <this.SimpleMultiSelectChart
                                                    label="Select Authors"
                                                    id="authors"
                                                    stateContext={stateContext}
                                                />
                                            </ChartWithFilter>
                                        </ThreeColChartGroup>
                                        <Last />
                                    </Filters>
                                    <Summary>
                                        <DataCount
                                            data={this.getGroup('dataCountData').all()}
                                            itemTypeName="extracted groups"
                                        />
                                    </Summary>
                                    <div>
                                        {this.state.crossfilterView === View.ANSWER &&
                                        this.state.crossfilterAnswer ? (
                                            <>
                                                <ToolsGrid>
                                                    <span>
                                                        Display at most{' '}
                                                        <NarrowSelect
                                                            value={this.state.maxClustersToShow}
                                                            onChange={(v: number) =>
                                                                this.setState({
                                                                    maxClustersToShow: v
                                                                })
                                                            }>
                                                            {[10, 20, 30, 40, 50, 60, 70, 80].map(
                                                                v => (
                                                                    <Select.Option
                                                                        key={v}
                                                                        value={v}>
                                                                        {v}
                                                                    </Select.Option>
                                                                )
                                                            )}
                                                        </NarrowSelect>{' '}
                                                        groups.
                                                    </span>
                                                    <LegendGrid>
                                                        <span>Relevance:</span>
                                                        <span>High</span>
                                                        <ColorRect background="B4" />
                                                        <ColorRect background="B3" />
                                                        <ColorRect background="B2" />
                                                        <ColorRect background="B1" />
                                                        <span>Low</span>
                                                    </LegendGrid>
                                                </ToolsGrid>
                                                <Directions>
                                                    You can use the mouse wheel to zoom in/out and
                                                    drag boxes to tease them apart.
                                                </Directions>
                                                <NodeNetwork
                                                    data={this.getGroup('nodeData').all()}
                                                    rankFilter={this.state.maxClustersToShow}
                                                    forceStop={this.state.forceStop}
                                                    onClusterClick={this.handelClusterClick}
                                                />
                                            </>
                                        ) : null}

                                        {this.state.crossfilterView === View.LOADING ? (
                                            <Loading message="Loading Author Clusters ..." />
                                        ) : null}

                                        {this.state.crossfilterView === View.ERROR &&
                                        this.state.crossfilterError ? (
                                            <Error
                                                message={`Error loading Author Clusters: ${this.state.crossfilterError}`}
                                            />
                                        ) : null}

                                        {this.state.clusterDetailsView === View.ANSWER &&
                                        this.state.clusterDetailsAnswer ? (
                                            <>
                                                <Heading>Selected Cluster Details</Heading>
                                                <TightCollapse
                                                    defaultActiveKey={['1', '2', '3', '4']}
                                                    expandIconPosition="right">
                                                    <Collapse.Panel header="Papers" key="1">
                                                        <List
                                                            size="large"
                                                            itemLayout="vertical"
                                                            pagination={
                                                                this.state.clusterDetailsAnswer
                                                                    .answer.cluster.papers.length >
                                                                5
                                                                    ? { pageSize: 5 }
                                                                    : false
                                                            }
                                                            dataSource={
                                                                this.state.clusterDetailsAnswer
                                                                    .answer.cluster.papers
                                                            }
                                                            renderItem={(paper: Paper) => (
                                                                <List.Item>
                                                                    <PaperSummary paper={paper} />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </Collapse.Panel>
                                                    <Collapse.Panel header="Topics" key="2">
                                                        <SmallList
                                                            dataSource={
                                                                this.state.clusterDetailsAnswer
                                                                    .answer.cluster.topics
                                                            }
                                                        />
                                                    </Collapse.Panel>
                                                    <Collapse.Panel header="Affiliations" key="3">
                                                        <SmallList
                                                            dataSource={
                                                                this.state.clusterDetailsAnswer
                                                                    .answer.cluster.affiliations
                                                            }
                                                        />
                                                    </Collapse.Panel>
                                                    <Collapse.Panel header="Authors" key="4">
                                                        <SmallList
                                                            dataSource={
                                                                this.state.clusterDetailsAnswer
                                                                    .answer.cluster.authors
                                                            }
                                                        />
                                                    </Collapse.Panel>
                                                </TightCollapse>
                                            </>
                                        ) : null}

                                        {this.state.clusterDetailsView === View.EMPTY ? (
                                            <Heading>
                                                Click on a cluster above to see details
                                            </Heading>
                                        ) : null}

                                        {this.state.clusterDetailsView === View.LOADING ? (
                                            <Loading message="Loading Cluster Details ..." />
                                        ) : null}

                                        {this.state.clusterDetailsView === View.ERROR &&
                                        this.state.clusterDetailsError ? (
                                            <Error
                                                message={`Error loading details: ${this.state.clusterDetailsError}`}
                                            />
                                        ) : null}
                                    </div>
                                </>
                            ) : (
                                <Loading message="Initializing ..." />
                            )}
                        </MainWrapper>
                    );
                }}
            </StateContext.Consumer>
        );
    }

    SimpleRowChart = ({ title, id }: { title: string; id: string }) => (
        <RowChart
            title={title}
            dimension={this.getDim(id + 'Dimension')}
            group={this.getGroup(id + 'Group')}
            initialFilter={this.getInitialRowFilter(id + 'Dimension')}
            groupId={id}
        />
    );

    // todo: fix up filter ('as string[]' below)
    SimpleMultiSelectChart = ({
        label,
        id,
        stateContext
    }: {
        label: string;
        id: string;
        stateContext: StateContextType;
    }) => (
        <MultiSelectChart
            placeholder={label}
            itemTypeName="Clusters"
            dimension={this.getDim(id + 'Dimension')}
            group={this.getGroup(id + 'Group')}
            initialFilter={this.getInitialRowFilter(id + 'Dimension') as string[]}
            groupId={id}
            stateContext={stateContext}
        />
    );
}

export const ThreeColChartGroup = styled(ChartGroup)`
    width: calc((100% - 50px) / 3);
`;

export const TightCollapse = styled(Collapse)`
    &&& {
        background: none;
    }
`;

export const NarrowSelect = styled(Select)`
    &&& {
        width: 60px;
        margin: 0;

        .ant-select-arrow {
            margin-right: 0;
        }
    }
`;

export const Directions = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

export const ToolsGrid = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: ${({ theme }) => theme.spacing.md};
    align-items: center;

    @media ${({ theme }) => below(theme.breakpoints.md)} {
        grid-template-columns: 1fr;
        grid-row-gap: ${({ theme }) => theme.spacing.xxs};
    }
`;

export const LegendGrid = styled.div`
    display: grid;
    grid-column-gap: ${({ theme }) => theme.spacing.xxs};
    grid-template-columns: auto auto repeat(4, 1fr) auto;
    align-items: center;
`;

export const ColorRect = styled.div<{ background: string }>`
    background: ${({ theme, background }) => theme.color[background]};
    height: ${({ theme }) => theme.spacing.lg};
    width: 100%;
`;
