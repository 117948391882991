import * as React from 'react';
import * as dc from 'dc';
import { scaleTime, scaleLinear } from 'd3-scale';

import { ChartTemplate } from './chartTemplate';
import { applyResizing } from './util';
import { StateContext, StateContextType } from '../StateContext';

interface Props<T> {
    title: string;
    group: any;
    dimension: any;
    className?: string;
    width?: number;
    height?: number;
    elasticY?: boolean;
    margin?: { top: number; bottom: number; left: number; right: number };
    renderArea?: boolean;
    brushOn?: boolean;
    initialFilter?: dc.Filter;
    groupId?: string;
}

const chartFunc = function<T>(divRef: any, chartProps: Props<T>, _: StateContextType) {
    const xDomain = chartProps.group.all();
    const margin = chartProps.margin || { top: 5, bottom: 45, left: 5, right: 10 };
    const width = chartProps.width || 710;
    const height = chartProps.height || 120;
    const renderArea = chartProps.renderArea !== undefined ? chartProps.renderArea : true;
    const elasticY = chartProps.elasticY !== undefined ? chartProps.elasticY : true;
    const brushOn = chartProps.brushOn !== undefined ? chartProps.brushOn : true;

    const dateRound = 15768000000; // 6 mo

    const chart = dc
        .lineChart(divRef)
        .dimension(chartProps.dimension)
        .group(chartProps.group)
        .keyAccessor(d => {
            return new Date(d.key);
        })
        .height(height)
        .width(width)
        .x(scaleTime().domain([xDomain[0].key, xDomain[xDomain.length - 1].key]))
        .y(scaleLinear())
        .brushOn(brushOn)
        .elasticY(elasticY)
        .margins(margin)
        .colorAccessor(_ => 0)
        .colors(['#2376E5'])
        .renderArea(renderArea)
        .round(v => Math.round(v / dateRound) * dateRound)
        .transitionDuration(0);

    chart.filterHandler((dimension, filters) => {
        if (filters && filters[0]) {
            dimension.filter([
                filters[0].map((d: Number | Date) => (d instanceof Date ? d.getTime() : d))
            ]);
        } else {
            dimension.filter(null);
        }
        return filters;
    });

    chart.filterPrinter(function(filters) {
        return `[${new Date(filters[0][0]).toLocaleDateString(
            'en-US'
        )} - ${new Date(filters[0][1]).toLocaleDateString('en-US')}]`;
    });

    applyResizing(chart, divRef, chartProps.title);

    return chart;
};

export class LineChart<T> extends React.Component<Props<T>> {
    render() {
        return (
            <StateContext.Consumer>
                {stateContext => {
                    return (
                        <ChartTemplate
                            stateContext={stateContext}
                            chartFunction={chartFunc}
                            chartProps={this.props}
                        />
                    );
                }}
            </StateContext.Consumer>
        );
    }
}
