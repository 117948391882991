import React from 'react';
import styled from 'styled-components';
import { Footer as VFooter, Body, Content } from '@allenai/varnish/components';

export const Footer = () => (
    <VFooter>
        <Content>
            <TextLeft>
                <Body>
                    <a href="https://allenai.org">
                        © The Allen Institute for Artificial Intelligence
                    </a>{' '}
                    - All Rights Reserved |{' '}
                    <a href="https://allenai.org/privacy-policy.html">Privacy Policy</a> |{' '}
                    <a href="https://allenai.org/terms.html">Terms of Use</a>
                    <br />
                    The{' '}
                    <a href="https://pages.semanticscholar.org/coronavirus-research" rel="noopener">
                        CORD-19
                    </a>{' '}
                    dataset is subject to the{' '}
                    <a href="https://ai2-semanticscholar-cord-19.s3-us-west-2.amazonaws.com/2020-03-13/COVID.DATA.LIC.AGMT.pdf">
                        Dataset License
                    </a>
                </Body>
            </TextLeft>
        </Content>
    </VFooter>
);

const TextLeft = styled.div`
    text-align: left;
`;
