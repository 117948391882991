/**
 * This file is meant for shared display components that you use throughout
 * your application.
 *
 * Components with a lot of logic, or those that are particularly complicated
 * should probably be put in their own file. This file is meant for the
 * re-usable, simple things used in a lot of different spots in your UI.
 */
import * as React from 'react';
import styled from 'styled-components';
import { Icon, BodyBig } from '@allenai/varnish/components';
import { below } from '@allenai/varnish/theme/breakpoints';
import { List } from 'antd';

export const LightPaper = styled.div`
    max-width: max-content;
    background: ${({ theme }) => theme.palette.background.info};
    padding: ${({ theme }) => theme.spacing.md};
    border: ${({ theme }) => `solid 1px ${theme.palette.border.info}`};
    border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
`;

export const LoadingIcon = styled(Icon).attrs({
    type: 'loading'
})`
    font-size: 30px;
`;

export const WarningIcon = styled(Icon).attrs({
    type: 'warning'
})`
    font-size: ${({ theme }) => theme.typography.bodyJumbo.fontSize};
`;

export const Loading: React.SFC<{ message: string }> = ({ message }) => (
    <LaodingGrid>
        <LoadingIcon /> {message}
    </LaodingGrid>
);

export const Error: React.SFC<{ message: string }> = ({ message }) => (
    <ErrorGrid>
        <WarningIcon /> {message}
    </ErrorGrid>
);

export const TwoColumnGrid = styled.div`
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: ${({ theme }) => `${theme.spacing.xs}`};
    align-items: center;
`;

export const ErrorGrid = styled(TwoColumnGrid)`
    color: ${({ theme }) => theme.palette.text.error.hex};
`;

export const LaodingGrid = styled(TwoColumnGrid)`
    margin-top: ${({ theme }) => theme.spacing.md};
`;

export const Filters = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: stretch;
    align-content: stretch;
`;

export const ChartWithFilter = styled.div`
    margin-bottom: 1.2rem;
    width: 100%;
`;

export const FullWidthChartGroup = styled.div`
    margin: 5px;
    width: 100%;
`;

export const ChartGroup = styled.div`
    margin: 5px;
    width: calc((100% - 50px) / 4);

    @media ${({ theme }) => below(theme.breakpoints.md)} {
        width: calc((100% - 50px) / 3);
    }
    @media ${({ theme }) => below(theme.breakpoints.sm)} {
        width: calc((100% - 50px) / 2);
    }
    @media ${({ theme }) => below(theme.breakpoints.xs)} {
        width: calc((100% - 50px) / 1);
    }
`;

export const Last = styled.div`
    flex-grow: 10;
`;

export const Heading = styled.h4`
    margin-top: ${({ theme }) => theme.spacing.sm};
`;

export const Item = styled(List.Item)`
    ${({ theme }) => `
        && {
            padding: ${theme.spacing.lg} ${theme.spacing.md};
        }
    `}
`;

export const SimpleItem = styled(List.Item)`
    && {
        padding: 0;
        border: none;
    }
`;

export const MainWrapper = styled.div`
    max-width: ${({ theme }) => theme.breakpoints.md};
`;

export const IntroText = styled(BodyBig)`
    ${({ theme }) => `
        margin-bottom: ${theme.spacing.md};
        max-width: 70ch;
        p:last-child {
            margin: 0;
        }
    `}
`;

export const Summary = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.md};
    padding-bottom: ${({ theme }) => theme.spacing.md};
    border-bottom: 0.5px solid ${({ theme }) => theme.color.N7};
`;

export const SmallList: React.SFC<{ title?: string; dataSource: string[] }> = ({
    title,
    dataSource
}) => (
    <List
        header={title ? <div>{title}</div> : undefined}
        size="small"
        pagination={dataSource.length > 10 ? { simple: true, pageSize: 10 } : false}
        dataSource={dataSource}
        renderItem={(item: string) => <SimpleItem>{item}</SimpleItem>}
    />
);
