import React from 'react';
import styled from 'styled-components';
import { Select } from '@allenai/varnish/components';
import { Spin } from 'antd';
import debounce from 'debounce';

import { StateContextType } from '../StateContext';

const { Option } = Select;

interface Props<T> {
    placeholder?: string;
    itemTypeName?: string;
    options: T[];
    initialSelectedValues?: T[];
    onChange?: (options: string[]) => void;
    groupId: string;
    stateContext: StateContextType;
}

interface State<T> {
    filteredOptions: T[];
    selectedValues: T[];
    fetching: boolean;
}

export class MultiSelect<T extends { key: any; value: any }> extends React.Component<
    Props<T>,
    State<T>
> {
    constructor(props: Props<T>) {
        super(props);

        this.state = {
            filteredOptions: [],
            selectedValues: this.props.initialSelectedValues || [],
            fetching: false
        };
    }

    componentDidMount() {
        // store our new chart in the global context
        if (this.props.stateContext && this.props.stateContext.state) {
            const { multiSelects } = this.props.stateContext.state;
            if (multiSelects) {
                multiSelects[this.props.groupId] = this;
                this.props.stateContext.updateState &&
                    this.props.stateContext.updateState({ multiSelects });
            }
        }
    }

    fetchItem = (str: string) => {
        this.setState({ filteredOptions: [], fetching: true }, () => {
            const data = this.props.options
                .filter((opt: T) => opt.key.toUpperCase().indexOf(str.toUpperCase()) !== -1)
                .sort((a, b) => b.value - a.value)
                .slice(0, 100);
            this.setState({ filteredOptions: data, fetching: false });
        });
    };

    fetchItemDebounced = debounce(this.fetchItem, 800);

    handleChange = (values: T[]) => {
        this.setState({
            selectedValues: values,
            filteredOptions: [],
            fetching: false
        });

        // tell other charts about the change
        if (
            this.props.groupId &&
            this.props.stateContext &&
            this.props.stateContext.state &&
            this.props.stateContext.state.charts
        ) {
            const chart = this.props.stateContext.state.charts[this.props.groupId];
            if (chart) {
                chart.filter(null);
                chart.filter(values.map(v => v.key));
            }
        }
    };

    filter = (filters: string[]) => {
        const selectedValues = this.props.options.filter(
            (opt: any) => filters.indexOf(opt.key) !== -1
        );
        this.setState({ selectedValues });
    };

    render() {
        return (
            <FullSelect
                size="small"
                mode="multiple"
                labelInValue
                value={this.state.selectedValues}
                placeholder={this.props.placeholder || 'Select item'}
                notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={this.fetchItemDebounced}
                maxTagTextLength={15}
                maxTagCount={5}
                onChange={this.handleChange}>
                {this.state.filteredOptions.map(d => {
                    const v = `${d.key.split('::')[0]}: ${d.value} matched ${this.props
                        .itemTypeName || 'items'}`;
                    return (
                        <Option key={d.key} title={v}>
                            {v}
                        </Option>
                    );
                })}
            </FullSelect>
        );
    }
}

const FullSelect = styled(Select)`
    width: 100%;
`;
