import { select } from 'd3-selection';

export const applyResizing = (
    chart: any,
    container: any,
    id: string,
    adjustX?: number,
    onresize?: any
) => {
    const aX = adjustX || 0;
    chart.width(container.parentNode.clientWidth - aX);
    select(window).on('resize.' + id, () => {
        if (onresize) {
            onresize(chart);
        }
        chart.width(container.parentNode.clientWidth - aX);
        if (chart.rescale) {
            chart.rescale();
        }
        chart.redraw();
    });
};

export const copy = function<T>(src: T) {
    return Object.assign({}, src);
};

export const copyArr = function<T>(arr: T[]) {
    return arr.map((src: T) => copy(src));
};

export const centerSq = function(strength: number = 0.0005, pow: number = 2) {
    let nodes: any[] = [];

    function force(alpha: number) {
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            // x
            const xSign = node.x < 0 ? -1 : 1;
            node.vx = node.vx - xSign * Math.pow(node.x, pow) * strength * alpha;
            // y
            const ySign = node.y < 0 ? -1 : 1;
            node.vy = node.vy - ySign * Math.pow(node.y, pow) * strength * alpha;
        }
    }

    function initialize() {}

    force.initialize = function(_: any[]) {
        nodes = _;
        initialize();
    };

    return force;
};

export const removeEmptyBins = (sourceGroup: any) => {
    return {
        all: () => {
            return sourceGroup.all().filter((d: any) => {
                // todo: use dc-group interface
                return d.value && d.value !== 0;
            });
        }
    };
};

export const ellipsify = (str: string, max: number = 11) => {
    return str.length > max ? `${str.substring(0, max - 1)}…` : str;
};
