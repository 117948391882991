import React from 'react';
import styled from 'styled-components';
import { select, Selection } from 'd3-selection';
import {
    forceSimulation,
    forceLink,
    forceCollide,
    forceManyBody,
    Simulation,
    SimulationNodeDatum,
    SimulationLinkDatum
} from 'd3-force';
import * as d3 from 'd3';
import { DefaultVarnishTheme } from '@allenai/varnish/theme';

import { centerSq, ellipsify } from './util';
import { Cluster, ClusterLink } from '../api';

import affiliationSrc from '../icons/noun_Enterprise_2339688.svg';
import topicSrc from '../icons/noun_Idea_2339722.svg';
import authorSrc from '../icons/noun_team_2339682.svg';

type AnySelection = Selection<any, any, any, any>; // todo: any

interface ClusterNodeDatum extends SimulationNodeDatum {
    id: string;
    authors: string[];
    topics: string[];
    affiliations: string[];
    hoverHtml: string;
    score: number;
    scoreColor: string;
}

interface ClusterLinkDatum extends SimulationLinkDatum<ClusterNodeDatum> {
    id: string;
    hoverHtml: string;
    cat: string;
    size: number;
}

interface Palette {
    linkStrokeTopi: string;
    linkStrokeTopi2: string;
    linkStrokeAuth: string;
    linkStrokeAuth2: string;
    linkHoverStroke: string;
    nodeStroke: string;
    nodeHoverStroke: string;
    nodeSelectedFill: string;
    nodeFill: string;
    tooltipBorder: string;
}

interface Props {
    data: { groups: Cluster[]; links: ClusterLink[] };
    rankFilter: number;
    onClusterClick?: (clusterId: string) => void;
    forceStop?: boolean;
    className?: string;
}

interface State {
    nodes: ClusterNodeDatum[];
    links: ClusterLinkDatum[];
}

export class NodeNetwork extends React.Component<Props, State> {
    static clusterWidth = 185;
    static clusterHeight = 185;
    svg: React.RefObject<SVGSVGElement>;
    svgg: React.RefObject<SVGGElement>;
    minWidth = 400;
    minHeight = 400;
    force: Simulation<ClusterNodeDatum, ClusterLinkDatum> = forceSimulation();

    palette: Palette = {
        linkStrokeTopi: DefaultVarnishTheme.color.P8.toString(),
        linkStrokeTopi2: DefaultVarnishTheme.color.P3.toString(),
        linkStrokeAuth: DefaultVarnishTheme.color.G8.toString(),
        linkStrokeAuth2: DefaultVarnishTheme.color.G3.toString(),
        linkHoverStroke: DefaultVarnishTheme.color.O6.toString(),
        nodeStroke: DefaultVarnishTheme.color.B9.toString(),
        nodeHoverStroke: DefaultVarnishTheme.color.O6.toString(),
        nodeSelectedFill: DefaultVarnishTheme.color.O2.toString(),
        nodeFill: DefaultVarnishTheme.color.B1.toString(),
        tooltipBorder: DefaultVarnishTheme.color.B3.toString()
    };

    constructor(props: Props) {
        super(props);

        this.svg = React.createRef();
        this.svgg = React.createRef();

        this.state = {
            nodes: [],
            links: []
        };
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: Props) {
        if (nextProps.data !== prevState.data || nextProps.rankFilter !== prevState.rankFilter) {
            const getSize = (min: number, max: number, size: number, maxSize: number) => {
                return min + ((max - min) * size) / (maxSize || 1);
            };

            let maxAuthLinkCount = 0;
            let maxTopiLinkCount = 0;
            let maxScore = 0;
            const nodes: ClusterNodeDatum[] = [];
            nextProps.data.groups
                .filter(g => g.rank <= nextProps.rankFilter)
                .forEach(group => {
                    maxScore = Math.max(maxScore, group.score);
                    nodes.push({
                        x: 0,
                        y: 0,
                        authors: group.topAuthors.map(a => a.split('::')[0]),
                        topics: group.topTopics,
                        affiliations: group.topAffiliations,
                        id: group.id,
                        score: group.score,
                        scoreColor: 'white',
                        hoverHtml: `
                            <div style="margin-bottom: 5px">
                                <div style="font-weight:bold">Top Authors</div>
                                <div>${group.topAuthors
                                    .map(a => a.split('::')[0])
                                    .join('</br>')}</div>
                            </div>
                            <div style="margin-bottom: 5px">
                                <div style="font-weight:bold">Top Topics</div>
                                <div>${group.topTopics.join('</br>')}</div>
                            </div>
                            <div>
                                <div style="font-weight:bold">Top Affiliations</div>
                                <div>${group.topAffiliations.join('</br>')}</div>
                            </div>`
                    });
                });
            nodes.forEach((n, i) => {
                const s = getSize(1, 4, n.score, maxScore);

                n.scoreColor = (DefaultVarnishTheme.color as any)[`B${Math.round(s)}`].toString();

                // The points animate to their final pos. This starts them in an outer circle.
                const d = ((i % 10) / 10) * Math.PI * 2;
                n.x = Math.cos(d) * 455;
                n.y = Math.sin(d) * 455;
            });

            const links: ClusterLinkDatum[] = [];
            nextProps.data.links
                .filter(li => li.rank <= nextProps.rankFilter)
                .forEach((link, i) => {
                    if (link.cat === 'auth') {
                        maxAuthLinkCount = Math.max(maxAuthLinkCount, link.values.length);
                    } else {
                        maxTopiLinkCount = Math.max(maxTopiLinkCount, link.values.length);
                    }
                    links.push({
                        id: i.toString(),
                        source: link.a1,
                        target: link.a2,
                        cat: link.cat,
                        size: link.values.length,
                        hoverHtml: link.values.join('</br>')
                    });
                });
            links.forEach(
                li =>
                    (li.size = getSize(
                        0.5,
                        10,
                        li.size,
                        li.cat === 'auth' ? maxAuthLinkCount : maxTopiLinkCount
                    ))
            );
            return {
                nodes,
                links,
                data: nextProps.data
            };
        }
        return prevState;
    }

    componentDidMount() {
        this.setViewBox();

        // tooltip
        // this could be placed and styled elsewhere, but its fine for now
        select('body')
            .append('div')
            .attr('id', 'tooltip')
            .style('opacity', 0)
            .style('position', 'absolute')
            .style('text-align', 'left')
            .style('pointer-events', 'none')
            .style('padding', () => DefaultVarnishTheme.spacing.xxs.toString())
            .style('font-size', () => DefaultVarnishTheme.typography.bodyMicro.fontSize.toString())
            .style('line-height', () =>
                DefaultVarnishTheme.typography.bodyMicro.lineHeight.toString()
            )
            .style('font-family', () =>
                DefaultVarnishTheme.typography.bodyMicro.fontFamily.toString()
            )
            .style('border', `1px solid ${this.palette.tooltipBorder}`)
            .style('background', 'white')
            .style('border-radius', () => DefaultVarnishTheme.spacing.xxs.toString());

        (select(this.svg.current) as any) // hmm
            .call(
                d3
                    .zoom()
                    .scaleExtent([1, 10])
                    .on('zoom', this.zoomed)
            );

        this.update();
    }

    zoomed = () => {
        if (d3.event && d3.event.transform) {
            select(this.svgg.current).attr('transform', d3.event.transform.toString());
        }
    };

    shouldComponentUpdate(nextProps: Props) {
        if (nextProps.data !== this.props.data || nextProps.rankFilter !== this.props.rankFilter) {
            // must 'wait' for getDerivedStateFromProps to apply state
            this.setState({}, () => {
                this.force.stop();
                this.update();
            });
        } else if (this.props.forceStop) {
            this.force.stop();
        }

        // d3 is controlling render cycle for this
        return false;
    }

    componentWillUnmount() {
        this.force.stop();
    }

    addForces = (simulation: any) => {
        simulation
            .force('centerSq', centerSq())
            .force('charge', forceManyBody().strength(-30))
            .force(
                'link',
                forceLink<any, any>(this.state.links)
                    .id(d => d.id)
                    .distance(10)
            )
            .force('collide', forceCollide(NodeNetwork.clusterWidth * 0.777));
    };

    removeForces = (simulation: any) => {
        simulation
            .force('charge', null)
            .force('centerSq', null)
            .force('link', null)
            .force('collide', null);
    };

    update = () => {
        const d3Graph = select(this.svgg.current);

        var d3Links = d3Graph.selectAll('.link').data(this.state.links, (link: any) => link.id);
        d3Links
            .enter()
            .insert('path', '.link')
            .call(this.enterLink);
        d3Links.exit().remove();
        d3Links.call(this.updateLink);

        var d3Nodes = d3Graph.selectAll('.node').data(this.state.nodes, (node: any) => node.id);
        d3Nodes
            .enter()
            .append('g')
            .call(this.enterNode);
        d3Nodes.exit().remove();
        d3Nodes.call(this.updateNode);

        // set up simulation
        this.force = forceSimulation<ClusterNodeDatum, ClusterLinkDatum>(
            this.state.nodes
        ).alphaDecay(0.5);
        this.addForces(this.force);

        d3.range(30).forEach(this.force.tick);

        // just jump to the final layout initially
        this.force.on('tick', this.tick);

        this.force.restart();
    };

    setViewBox = (
        left = -this.minWidth / 2,
        top = -this.minHeight / 2,
        width = this.minWidth,
        height = this.minHeight
    ) => {
        select(this.svg.current)
            .attr('viewBox', `${left}, ${top}, ${width}, ${height}`)
            .attr('preserveAspectRatio', 'xMidYMid meet');
    };

    tick = () => {
        const d3Graph = select(this.svgg.current);

        // after force calculation starts, call updateGraph
        // which uses d3 to manipulate the attributes,
        // and React doesn't have to go through lifecycle on each tick
        d3Graph.call(this.updateGraph);

        // get bounds and zoom to it
        let minX = -this.minWidth / 2;
        let maxX = this.minWidth / 2;
        let minY = -this.minHeight / 2;
        let maxY = this.minHeight / 2;
        this.state.nodes.forEach(n => {
            minX = Math.min(minX, (n.x || 0) - (5 + NodeNetwork.clusterWidth / 2));
            maxX = Math.max(maxX, (n.x || 0) + (5 + NodeNetwork.clusterWidth / 2));
            minY = Math.min(minY, (n.y || 0) - (5 + NodeNetwork.clusterHeight / 2));
            maxY = Math.max(maxY, (n.y || 0) + (5 + NodeNetwork.clusterHeight / 2));
        });
        this.setViewBox(minX, minY, maxX - minX, maxY - minY);
    };

    getLinkColor = (d: any) => {
        switch (d.cat) {
            case 'auth':
                return 'url(#gradientAuth)';
            case 'topi':
            default:
                return 'url(#gradientTopi)';
        }
    };

    updateGraph = (selection: AnySelection) => {
        selection.selectAll('.node').call(this.updateNode);
        selection.selectAll('.link').call(this.updateLink);
    };

    enterNode = (selection: AnySelection) => {
        selection.classed('node', true);

        var tooltip = select('#tooltip');

        const circles = selection
            .append('rect')
            .attr('rx', 4)
            .attr('ry', 4)
            .attr('x', -NodeNetwork.clusterWidth / 2)
            .attr('y', -NodeNetwork.clusterHeight / 2)
            .attr('width', NodeNetwork.clusterWidth)
            .attr('height', NodeNetwork.clusterHeight)
            .attr('stroke', this.palette.nodeStroke)
            .attr('fill', this.palette.nodeFill)
            .attr('stroke-width', 1)
            .attr('cursor', 'pointer')
            .on('click', d => {
                circles.attr('fill', this.palette.nodeFill);
                select(d3.event.currentTarget).attr('fill', this.palette.nodeSelectedFill);
                this.props.onClusterClick && this.props.onClusterClick(d.id);
            })
            .on('mouseover', d => {
                select(d3.event.currentTarget)
                    .attr('stroke', this.palette.nodeHoverStroke)
                    .attr('stroke-width', 2);
                tooltip
                    .transition()
                    .duration(200)
                    .style('opacity', 0.9);
                tooltip
                    .html(d.hoverHtml)
                    .style('left', d3.event.pageX + 'px')
                    .style('top', d3.event.pageY - 28 + 'px');
            })
            .on('mouseout', () => {
                select(d3.event.currentTarget)
                    .attr('stroke', this.palette.nodeStroke)
                    .attr('stroke-width', 1);
                tooltip
                    .transition()
                    .duration(500)
                    .style('opacity', 0);
            });

        const lineHeight = 16;
        const imgSize = 40;
        const pad = 8;
        const trimLen = 13;

        selection
            .append('rect')
            .attr('rx', 4)
            .attr('ry', 4)
            .attr('x', -NodeNetwork.clusterWidth / 2 + (imgSize + pad * 2))
            .attr('y', -NodeNetwork.clusterHeight / 2)
            .attr('width', NodeNetwork.clusterWidth - (imgSize + pad * 2))
            .attr('height', NodeNetwork.clusterHeight)
            .attr('fill', (d: any) => d.scoreColor)
            .attr('pointer-events', 'none');

        const authorPos = [
            -NodeNetwork.clusterWidth / 2 + pad,
            -NodeNetwork.clusterHeight / 2 + pad / 2
        ];
        const topicPos = [
            -NodeNetwork.clusterWidth / 2 + pad,
            -NodeNetwork.clusterHeight / 2 + NodeNetwork.clusterHeight / 3
        ];
        const affiliationPos = [
            -NodeNetwork.clusterWidth / 2 + pad,
            NodeNetwork.clusterHeight / 2 - NodeNetwork.clusterHeight / 3 - pad / 2
        ];

        // authors
        selection
            .append('image')
            .attr('x', authorPos[0])
            .attr('y', authorPos[1] + lineHeight / 2)
            .attr('xlink:href', authorSrc)
            .attr('width', imgSize)
            .attr('height', imgSize)
            .attr('pointer-events', 'none');
        selection
            .selectAll('text.author')
            .data(d => d.authors.slice(0, 3))
            .enter()
            .append('text')
            .attr('class', 'author')
            .attr('x', authorPos[0] + imgSize + pad * 2)
            .attr('y', (_, i) => authorPos[1] + lineHeight * (i + 1))
            .attr('pointer-events', 'none')
            .text((d: any) => ellipsify(d, trimLen));

        // topics
        selection
            .append('image')
            .attr('x', topicPos[0])
            .attr('y', topicPos[1] + lineHeight / 2)
            .attr('xlink:href', topicSrc)
            .attr('width', imgSize)
            .attr('height', imgSize)
            .attr('pointer-events', 'none');
        selection
            .selectAll('text.topic')
            .data(d => d.topics.slice(0, 3))
            .enter()
            .append('text')
            .attr('class', 'topic')
            .attr('x', topicPos[0] + imgSize + pad * 2)
            .attr('y', (_, i) => topicPos[1] + lineHeight * (i + 1))
            .attr('pointer-events', 'none')
            .text((d: any) => ellipsify(d, trimLen));

        // affiliations
        selection
            .append('image')
            .attr('x', affiliationPos[0])
            .attr('y', affiliationPos[1] + lineHeight / 2)
            .attr('xlink:href', affiliationSrc)
            .attr('width', imgSize)
            .attr('height', imgSize)
            .attr('pointer-events', 'none');
        selection
            .selectAll('text.affiliation')
            .data(d => d.affiliations.slice(0, 3))
            .enter()
            .append('text')
            .attr('class', 'affiliation')
            .attr('x', affiliationPos[0] + imgSize + pad * 2)
            .attr('y', (_, i) => affiliationPos[1] + lineHeight * (i + 1))
            .attr('pointer-events', 'none')
            .text((d: any) => ellipsify(d, trimLen));

        selection.call(this.drag());
    };

    updateNode = (selection: AnySelection) => {
        selection.attr('transform', d => {
            return 'translate(' + d.x + ',' + d.y + ')';
        });
    };

    enterLink = (selection: AnySelection) => {
        var tooltip = select('#tooltip');

        selection
            .classed('link', true)
            .attr('stroke-width', d => d.size)
            .attr('fill', 'transparent')
            .attr('stroke', this.getLinkColor)
            .style('opacity', 0.7)
            .attr('cursor', 'pointer')
            .attr('pointer-events', 'visibleStroke')
            .on('mouseover', d => {
                select(d3.event.currentTarget)
                    .attr('stroke', this.palette.linkHoverStroke)
                    .attr('stroke-width', 8);
                tooltip
                    .transition()
                    .duration(200)
                    .style('opacity', 0.9);
                tooltip
                    .html(d.hoverHtml)
                    .style('left', d3.event.pageX + 'px')
                    .style('top', d3.event.pageY - 28 + 'px');
            })
            .on('mouseout', () => {
                select(d3.event.currentTarget)
                    .attr('stroke', this.getLinkColor)
                    .attr('stroke-width', (d: any) => d.size);
                tooltip
                    .transition()
                    .duration(500)
                    .style('opacity', 0);
            });
    };

    updateLink = (selection: AnySelection) => {
        selection.attr('d', d => {
            if (d.source.x) {
                const dx = d.target.x - d.source.x;
                const dy = d.target.y - d.source.y;
                const dr = Math.sqrt(dx * dx + dy * dy);
                return `M ${d.source.x},${d.source.y} A ${dr},${dr} 0 0,1 ${d.target.x},${d.target.y}`;
            }
            return null;
        });
    };

    drag = (): any => {
        const dragstarted = (d: any) => {
            if (!d3.event.active) {
                this.removeForces(this.force);
                this.force.alphaTarget(0.01).restart();
            }
            d.fx = d.x;
            d.fy = d.y;
        };

        const dragged = (d: any) => {
            d.fx = d3.event.x;
            d.fy = d3.event.y;
        };

        const dragended = (d: any) => {
            if (!d3.event.active) {
                this.addForces(this.force);
                this.force.alphaTarget(0);
            }
            d.fx = null;
            d.fy = null;
        };

        return d3
            .drag()
            .on('start', dragstarted)
            .on('drag', dragged)
            .on('end', dragended);
    };

    render() {
        return (
            <div className={this.props.className}>
                <StyledSvg ref={this.svg}>
                    <defs>
                        <linearGradient id="gradientTopi" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor={this.palette.linkStrokeTopi} />
                            <stop offset="100%" stopColor={this.palette.linkStrokeTopi2} />
                        </linearGradient>
                        <linearGradient id="gradientAuth" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor={this.palette.linkStrokeAuth} />
                            <stop offset="100%" stopColor={this.palette.linkStrokeAuth2} />
                        </linearGradient>
                    </defs>
                    <g ref={this.svgg}></g>
                </StyledSvg>
            </div>
        );
    }
}

const StyledSvg = styled.svg`
    width: 100%;
    height: auto;
    box-sizing: border-box;
`;
