import * as React from 'react';
import * as dc from 'dc';
import styled from 'styled-components';

import { ChartTemplate } from './chartTemplate';
import { applyResizing, removeEmptyBins } from './util';
import { AnyFilter } from '../api';
import { StateContext, StateContextType } from '../StateContext';

interface Props<T> {
    title: string;
    group: any;
    dimension: any;
    className?: string;
    initialFilter?: AnyFilter;
    groupId?: string;
    includeZeros?: boolean;
}

const chartFunc = function<T>(divRef: any, chartProps: Props<T>, stateContext: StateContextType) {
    const xDomain = chartProps.group.all();
    const margin = { top: 5, bottom: 5, left: 5, right: 10 };
    const pad = 5;
    const barHeight = 15;
    const maxTicks = 5;
    const maxRowsToShow = 15; // todo, pass this in
    const width = 154; // todo, pass this in
    const hideOthers = true; // todo: pass in

    let group = chartProps.group;
    if (!chartProps.includeZeros) {
        group = removeEmptyBins(chartProps.group);
    }

    const chart = dc.rowChart(divRef);
    chart
        .fixedBarHeight(barHeight)
        .height(
            Math.min(xDomain.length, maxRowsToShow + (hideOthers ? 0 : 1)) * (barHeight + pad) +
                margin.top +
                margin.bottom +
                pad
        )
        .width(width)
        .elasticX(true)
        .dimension(chartProps.dimension)
        .group(group)
        .margins(margin)
        .colorAccessor(_ => 0)
        .colors(['#2376E5'])
        .cap(maxRowsToShow)
        .label(d => d.key.split('::')[0])
        .title(d => `${d.key.split('::')[0]}: ${d.value}`);

    chart.xAxis().ticks(maxTicks);

    chart.on('filtered', function() {
        // tell multiSelelcts with the same dimension to filter
        if (
            chartProps.groupId &&
            stateContext &&
            stateContext.state &&
            stateContext.state.multiSelects
        ) {
            const select = stateContext.state.multiSelects[chartProps.groupId];
            if (select) {
                select.filter(chart.filters());
            }
        }
    });

    chart.filterHandler((dimension, filters) => {
        if (filters) {
            // how odd: todo: fix this up
            if (filters[0] instanceof Array) {
                filters = filters[0];
            }
            dimension.filter(filters);
        } else {
            dimension.filter(null);
        }
        return filters;
    });

    if (hideOthers) {
        (chart.othersGrouper as any)(false); // oh hack hack
    }

    applyResizing(chart, divRef, chartProps.title);

    return chart;
};

export class RowChart<T> extends React.Component<Props<T>> {
    render() {
        return (
            <StateContext.Consumer>
                {stateContext => {
                    return (
                        <Wrapper>
                            {' '}
                            <ChartTemplate
                                stateContext={stateContext}
                                chartFunction={chartFunc}
                                chartProps={this.props}
                            />
                        </Wrapper>
                    );
                }}
            </StateContext.Consumer>
        );
    }
}

const Wrapper = styled.div`
    &&& {
        span.filter {
            display: none;
        }
    }
`;
