import queryString from 'querystring';
import { Location } from 'history';

import { unwrap } from '../utils';

export class PapersQuery {
    constructor(public ids: string = '') {}

    isValid() {
        return this.ids && this.ids.length > 0;
    }

    equals(query: PapersQuery): boolean {
        return this.ids.toString() === query.ids.toString();
    }
}

export class ClusterDetailsQuery {
    constructor(public id: string = '') {}

    isValid() {
        return this.id !== '';
    }

    equals(query: ClusterDetailsQuery): boolean {
        return this.id === query.id;
    }
}

export class MapQuery {
    constructor(public d: string = 'jnlpba') {}

    isValid() {
        return this.d.trim() !== '';
    }

    equals(query: MapQuery): boolean {
        return this.d === query.d;
    }

    toQueryString(): string {
        return queryString.stringify({ d: this.d });
    }

    public static fromQueryString(location: Location): MapQuery {
        const locationSearchWithoutQuestionmark = location.search.replace(/^\?/, '');
        const qs = queryString.parse(locationSearchWithoutQuestionmark);
        return new MapQuery(unwrap(qs.d));
    }
}

export class ComentionQuery {
    constructor(
        public d: string = 'jnlpba',
        public ftm?: string,
        public l?: number,
        public ml?: number
    ) {}

    isValid() {
        return this.d.trim() !== '';
    }

    equals(query: ComentionQuery): boolean {
        return (
            this.d === query.d &&
            this.l === query.l &&
            this.ftm === query.ftm &&
            this.ml === query.ml
        );
    }

    toQueryString(): string {
        return queryString.stringify({ d: this.d, l: this.l, ftm: this.ftm, ml: this.ml });
    }

    public static fromQueryString(location: Location): ComentionQuery {
        const locationSearchWithoutQuestionmark = location.search.replace(/^\?/, '');
        const qs = queryString.parse(locationSearchWithoutQuestionmark);
        return new ComentionQuery(
            unwrap(qs.d),
            unwrap(qs.ftm),
            Number(unwrap(qs.l)),
            Number(unwrap(qs.ml))
        );
    }
}

export class TermsQuery {
    constructor(public d: string = 'jnlpba', public ftm: string, public m?: number) {}

    isValid() {
        return this.d.trim() !== '' && this.ftm.trim() !== '';
    }

    equals(query: TermsQuery): boolean {
        return this.d === query.d && this.ftm === query.ftm && this.m === query.m;
    }

    toQueryString(): string {
        return queryString.stringify({ d: this.d, ftm: this.ftm, m: this.m });
    }

    public static fromQueryString(location: Location): TermsQuery {
        const locationSearchWithoutQuestionmark = location.search.replace(/^\?/, '');
        const qs = queryString.parse(locationSearchWithoutQuestionmark);
        return new TermsQuery(unwrap(qs.d), unwrap(qs.ftm), Number(unwrap(qs.m)));
    }
}

type Range = [[number, number]];
type CategoryFilter = string[];
export type AnyFilter = Range | CategoryFilter | undefined;

export interface CrossfilterPapersFilter {
    [key: string]: AnyFilter;

    yearDimension?: Range;
    sourceXDimension?: CategoryFilter;
    licenseDimension?: CategoryFilter;
    authorsDimension?: CategoryFilter;
    authors2Dimension?: CategoryFilter;
    journalDimension?: CategoryFilter;
    charDimension?: CategoryFilter;
    interDimension?: CategoryFilter;
    outcomeDimension?: CategoryFilter;
}

export class CrossfilterPapersQuery {
    constructor(public filter?: CrossfilterPapersFilter) {}

    isValid() {
        return this.filter !== undefined;
    }

    equals(query: CrossfilterPapersQuery): boolean {
        return JSON.stringify(this.filter) === JSON.stringify(query.filter);
    }

    toQueryString(): string {
        return queryString.stringify({ filter: JSON.stringify(this.filter) });
    }

    public static fromQueryString(location: Location): CrossfilterPapersQuery {
        const locationSearchWithoutQuestionmark = location.search.replace(/^\?/, '');
        const qs = queryString.parse(locationSearchWithoutQuestionmark);
        return new CrossfilterPapersQuery(qs.filter ? JSON.parse(unwrap(qs.filter)) : undefined);
    }
}

export interface CrossfilterClustersFilter {
    [key: string]: AnyFilter;

    affiliationsDimension?: CategoryFilter;
    authorsDimension?: CategoryFilter;
    topicsDimension?: CategoryFilter;
    papersDimension?: CategoryFilter;
}

export class CrossfilterClustersQuery {
    constructor(public filter?: CrossfilterClustersFilter) {}

    isValid() {
        return this.filter !== undefined;
    }

    equals(query: CrossfilterClustersQuery): boolean {
        return JSON.stringify(this.filter) === JSON.stringify(query.filter);
    }

    toQueryString(): string {
        return queryString.stringify({ filter: JSON.stringify(this.filter) });
    }

    public static fromQueryString(location: Location): CrossfilterClustersQuery {
        const locationSearchWithoutQuestionmark = location.search.replace(/^\?/, '');
        const qs = queryString.parse(locationSearchWithoutQuestionmark);
        return new CrossfilterClustersQuery(qs.filter ? JSON.parse(unwrap(qs.filter)) : undefined);
    }
}
