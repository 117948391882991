import React from 'react';
import styled from 'styled-components';

import { MultiSelect } from './multiSelect';
import { StateContextType } from '../StateContext';

interface Props {
    group: any;
    dimension: any;
    initialFilter: string[];
    placeholder?: string;
    className?: string;
    groupId: string;
    itemTypeName?: string;
    stateContext: StateContextType;
}

export class MultiSelectChart extends React.Component<Props> {
    render() {
        const options = this.props.group.all();
        const initialSelected = this.props.initialFilter
            ? options.filter((opt: any) => this.props.initialFilter.indexOf(opt.key) !== -1)
            : undefined;
        return (
            <Wrapper className={this.props.className}>
                <MultiSelect
                    initialSelectedValues={initialSelected}
                    options={options}
                    placeholder={this.props.placeholder}
                    itemTypeName={this.props.itemTypeName}
                    groupId={this.props.groupId}
                    stateContext={this.props.stateContext}
                />
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    &&& {
        .ant-select-selection__choice {
            padding: 0 12px 0 4px;
            font-size: 8px;
        }
        .ant-select-selection__choice__remove {
            font-size: 8px;
        }
    }
`;
