import * as React from 'react';
import styled from 'styled-components';
import { InternalLink, Body } from '@allenai/varnish/components';
import { below } from '@allenai/varnish/theme/breakpoints';

import { MetaTags } from '../components';

import shareFacebookSrc from '../icons/social-facebook-32px.svg';
import shareTwitterSrc from '../icons/social-twitter-32px.svg';
import shareLinkedinSrc from '../icons/social-linkedin-32px.svg';

import jnlpbaSrc from '../icons/jnlpba.png';
import bc5cdr from '../icons/bc5cdr.png';
import facets from '../icons/facets.png';
import clusters from '../icons/clusters2.png';

export default class About extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <MetaTags
                    title="About"
                    description="Additional information about SciSight, a visual explorer for CORD-19"
                />
                <Body>
                    <h4>
                        <strong>SciSight</strong> is a tool for exploring the evolving network of
                        science in the{' '}
                        <a href="https://pages.semanticscholar.org/coronavirus-research">
                            COVID-19 Open Research Dataset
                        </a>
                        , from <a href="https://www.semanticscholar.org/">Semantic Scholar</a> at
                        the <a href="https://allenai.org/">Allen Institute for AI</a>.
                    </h4>
                    <p>
                        Our goal is to help <strong>accelerate scientific research</strong>, with
                        tools to visualize the emerging literature network around COVID-19. Use our
                        exploratory search tools to find out{' '}
                        <strong>what groups are working on what directions</strong>, see how
                        biomedical concepts interact and evolve over time, and discover new
                        connections.
                    </p>
                </Body>
                <UsageArea>
                    <Usage>
                        <UsageImg to={'/clusters/'}>
                            <img src={clusters} alt="Screenshot of author clusters" />
                        </UsageImg>
                        <div>
                            <h4>Network of science</h4>
                            <UsageDescription>
                                Explore the progress being made against COVID-19, with a
                                visualization of research groups and their ties.
                                <br />
                                <InternalLink to={'/clusters/'}>Explore Network →</InternalLink>
                            </UsageDescription>
                        </div>
                    </Usage>
                    <Usage>
                        <UsageImg to={'/facets/'}>
                            <img src={facets} alt="Screenshot of paper metadata facets" />
                        </UsageImg>
                        <div>
                            <h4>Faceted paper search</h4>
                            <UsageDescription>
                                See how authors and topics interact over time with this exploratory
                                faceted search tool.
                                <br />
                                <InternalLink to={'/facets/'}>Explore Facets →</InternalLink>
                            </UsageDescription>
                        </div>
                    </Usage>
                    <Usage>
                        <UsageImg to={'/jnlpba/'}>
                            <img
                                src={jnlpbaSrc}
                                alt="Screenshot of proteins/genes/cells co-mentions"
                            />
                        </UsageImg>
                        <div>
                            <h4>Co-mentions of proteins/genes/cells</h4>
                            <UsageDescription>
                                Explore associations between proteins/genes/cells concepts appearing
                                in the dataset.
                                <br />
                                <InternalLink to={'/jnlpba/'}>
                                    Explore proteins/genes/cells →
                                </InternalLink>
                            </UsageDescription>
                        </div>
                    </Usage>
                    <Usage>
                        <UsageImg to={'/bc5cdr/'}>
                            <img src={bc5cdr} alt="Screenshot of diseases/chemicals co-mentions" />
                        </UsageImg>
                        <div>
                            <h4>Co-mentions of diseases/chemicals</h4>
                            <UsageDescription>
                                Explore associations between diseases/chemicals concepts appearing
                                in the dataset.
                                <br />
                                <InternalLink to={'/bc5cdr/'}>
                                    Explore diseases/chemicals →
                                </InternalLink>
                            </UsageDescription>
                        </div>
                    </Usage>
                </UsageArea>
                <Body>
                    
                    <p>
                     <h4>To read about more about SciSight and cite this work, please see <a href="https://arxiv.org/abs/2005.12668/"> our EMNLP paper</a>.</h4>
                    </p>    
                    
                    <p>
                        For research inquiries, please contact{' '}
                        <a href="mailto:tomh@allenai.org">Tom Hope</a>. The co-authorship
                        visualization is joint work with Jason Portenoy, Kishore Vasan and Jevin
                        West from UW iSchool's
                        <a href="https://datalab.ischool.uw.edu/"> DataLab</a>. To extract
                        information from papers, we used{' '}
                        <a href="https://github.com/allenai/scibert">SciBERT</a>, enhanced by
                        training on a larger corpus of papers and fine-tuned on several biomedical
                        entity recognition tasks. We also make use of data from the
                        <a href="https://aka.ms/msracad"> Microsoft Academic Graph</a> and ICO
                        topics from <a href="https://drevidence.com/ "> Dr. Evidence</a>.
                    </p>
                </Body>
                <ShareArea>
                    <Body>Share this:</Body>
                    <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fscisight.apps.allenai.org%2F">
                        <img src={shareFacebookSrc} alt="Share on Facebook" />
                    </a>
                    <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fscisight.apps.allenai.org%2F&text=A%20tool%20for%20visually%20exploring%20the%20COVID-19%20Open%20Research%20Dataset.">
                        <img src={shareTwitterSrc} alt="Share on Twitter" />
                    </a>
                    <a href="http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fscisight.apps.allenai.org%2F&title=SciSight%20a%20visual%20explorer%20for%20CORD-19&summary=A%20tool%20for%20visually%20exploring%20the%20COVID-19%20Open%20Research%20Dataset.">
                        <img src={shareLinkedinSrc} alt="Share on Linkedin" />
                    </a>
                </ShareArea>
            </React.Fragment>
        );
    }
}

const UsageArea = styled.div`
    display: grid;
    gap: ${({ theme }) => theme.spacing.xl2};
    margin: ${({ theme }) => theme.spacing.xl2} 0;
`;

const Usage = styled.div`
    display: grid;
    grid-template-columns: 4fr 6fr;
    gap: ${({ theme }) => theme.spacing.xl};
    @media ${({ theme }) => below(theme.breakpoints.md)} {
        grid-template-columns: 1fr;
        gap: ${({ theme }) => theme.spacing.xs};
    }
`;

const UsageImg = styled(InternalLink)`
    img {
        width: 100%;
        max-width: 330px;
        box-shadow: rgba(10, 41, 57, 0.2) 0px 4px 12px;
    }
    @media ${({ theme }) => below(theme.breakpoints.md)} {
        order: 1;
    }
`;

const UsageDescription = styled.div`
    color: ${({ theme }) => theme.palette.text.primary};
    a {
        line-height: 2;
    }
    @media ${({ theme }) => below(theme.breakpoints.md)} {
        display: none;
    }
`;

const ShareArea = styled.div`
    display: grid;
    grid-template-columns: repeat(4, auto) 1fr;
    gap: ${({ theme }) => theme.spacing.xs};
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing.xl2};
`;
