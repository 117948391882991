import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as dc from 'dc';
import debounce from 'debounce';
import { List } from 'antd';
import { Body } from '@allenai/varnish/components';

import {
    filterPaperData,
    CrossfilterPapersQuery,
    CrossfilterPapersAnswer,
    CrossfilterPapersFilter,
    Paper
} from '../api';
import {
    MetaTags,
    RowChart,
    LineChart,
    Loading,
    DataCount,
    Error,
    PaperSummary,
    MultiSelectChart,
    Filters,
    ChartWithFilter,
    FullWidthChartGroup,
    ChartGroup,
    Last,
    Item,
    MainWrapper,
    IntroText,
    Summary,
    TryExamplesSimple
} from '../components';
import { StateContext, StateContextType } from '../StateContext';

enum View {
    EMPTY,
    LOADING,
    ANSWER,
    ERROR
}

interface Props extends RouteComponentProps {
    seed: CrossfilterPapersFilter;
}

interface State {
    initialized: boolean;
    forceStop?: boolean;

    crossfilterQuery: CrossfilterPapersQuery;
    crossfilterView: View;
    crossfilterAnswer?: CrossfilterPapersAnswer;
    crossfilterError?: string;
}

export default class MetadataFacets extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        const cq = CrossfilterPapersQuery.fromQueryString(props.location);
        if (!cq.filter || !cq.isValid()) {
            cq.filter = this.props.seed;
        }

        this.state = {
            initialized: false,
            crossfilterQuery: cq,
            crossfilterView: View.EMPTY
        };
    }

    componentDidMount() {
        this.fetchFilterPapersDebounced();
    }

    getDim = (id: string) => {
        return {
            filter: (f: any) => {
                const { crossfilterQuery } = this.state;
                if (!crossfilterQuery.filter) {
                    crossfilterQuery.filter = {};
                }
                crossfilterQuery.filter[id] = f;

                this.setState({ crossfilterQuery, forceStop: true }, () => {
                    this.fetchFilterPapersDebounced();
                });
            },
            filterAll: () => {}
        };
    };

    getGroup = (id: string) => {
        return {
            all: () => {
                if (
                    this.state.crossfilterView === View.ANSWER &&
                    this.state.crossfilterAnswer &&
                    this.state.crossfilterAnswer.answer
                ) {
                    return this.state.crossfilterAnswer.answer.filteredData[id].values;
                }
                return [];
            },
            order: () => {},
            top: () => {}
        };
    };

    getInitialRowFilter = (id: string) => {
        return this.state.crossfilterQuery.filter && this.state.crossfilterQuery.filter[id]
            ? this.state.crossfilterQuery.filter[id]
            : undefined;
    };

    fetchFilterPaperData() {
        this.props.history.push(`/Facets?${this.state.crossfilterQuery.toQueryString()}`);
        const originalCrossfilterQuery = this.state.crossfilterQuery;
        this.setState({ crossfilterView: View.LOADING }, () => {
            filterPaperData(this.state.crossfilterQuery)
                .then(answer => {
                    if (this.state.crossfilterQuery.equals(originalCrossfilterQuery)) {
                        console.log(answer); // todo: remove
                        this.setState(
                            {
                                forceStop: false,
                                initialized: true,
                                crossfilterView: View.ANSWER,
                                crossfilterError: undefined,
                                crossfilterAnswer: answer
                            },
                            () => {
                                dc.renderAll(); // render charts
                            }
                        );
                    }
                })
                .catch((err: any) => {
                    if (this.state.crossfilterQuery.equals(originalCrossfilterQuery)) {
                        let error;
                        if (err.response) {
                            if (err.response.data && err.response.data.error) {
                                error = err.response.data.error;
                            } else if (err.response.status === 503) {
                                error =
                                    'Our system is a little overloaded, ' +
                                    'please try again in a moment';
                            }
                        }
                        // Fallback to a general error message
                        if (!error) {
                            error = 'Something went wrong. Please try again.';
                        }
                        this.setState({
                            forceStop: false,
                            crossfilterView: View.ERROR,
                            crossfilterAnswer: undefined,
                            crossfilterError: error
                        });
                    }
                });
        });
    }

    fetchFilterPapersDebounced = debounce(this.fetchFilterPaperData, 300);

    render() {
        return (
            <StateContext.Consumer>
                {stateContext => {
                    return (
                        <MainWrapper>
                            <MetaTags
                                title="CORD-19 Faceted Paper Search"
                                description="See how authors and topics interact over time with this exploratory faceted search tool."
                            />
                            <IntroText as="div">
                                <h4>
                                    Search and discover key facets of the{' '}
                                    <a
                                        href="https://pages.semanticscholar.org/coronavirus-research"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        COVID-19 Research Dataset
                                    </a>{' '}
                                </h4>
                                <p>
                                    <Body>
                                        Select authors,{' '}
                                        <a
                                            href="https://en.wikipedia.org/wiki/PICO_process"
                                            rel="noopener noreferrer"
                                            target="_blank">
                                            clinical topics
                                        </a>{' '}
                                        and journals, and see how the different facets interact over
                                        time. Scroll down to see relevant papers.
                                    </Body>
                                    <br />
                                </p>
                            </IntroText>
                            {this.state.initialized ? (
                                <>
                                    <TryExamplesSimple
                                        examples={[
                                            {
                                                label: 'Author: Jiang, Shibo and Tai, Wanbo',
                                                filter:
                                                    '{"authorsDimension":["Jiang, Shibo"],"authors2Dimension":["Tai, Wanbo"]}'
                                            },
                                            {
                                                label:
                                                    'Characteristic: Age: and Intervention: Education',
                                                filter:
                                                    '{"charDimension":["Age"],"interDimension":["Education"]}'
                                            },
                                            {
                                                label: 'Old Papers',
                                                filter:
                                                    '{"yearDimension":[[-599184000000,315360000000]]}'
                                            },
                                            {
                                                label: 'Recent Papers',
                                                filter:
                                                    '{"yearDimension":[[1434888000000,1592568000000]]}'
                                            },

                                            {
                                                label: 'Journal: The Lancet',
                                                filter: '{"journalDimension":["The Lancet"]}'
                                            },
                                            {
                                                label: 'License: unk and Source: WHO',
                                                filter:
                                                    '{"licenseDimension":["unk"],"sourceXDimension":["WHO"]}'
                                            },
                                            {
                                                label: 'Outcome: (Mortality or Death)',
                                                filter:
                                                    '{"outcomeDimension":["Mortality", "Death"]}'
                                            }
                                        ]}
                                        queryUrl={`facets/?filter=`}
                                        maxLinks={4}
                                    />
                                    <Filters>
                                        <FullWidthChartGroup>
                                            <LineChart
                                                title="Papers per Year"
                                                dimension={this.getDim('yearDimension')}
                                                group={this.getGroup('yearGroup')}
                                                initialFilter={
                                                    this.state.crossfilterQuery.filter &&
                                                    this.state.crossfilterQuery.filter.yearDimension
                                                        ? dc.filters.RangedFilter(
                                                              this.state.crossfilterQuery.filter
                                                                  .yearDimension[0][0],
                                                              this.state.crossfilterQuery.filter
                                                                  .yearDimension[0][1]
                                                          )
                                                        : undefined
                                                }
                                            />
                                        </FullWidthChartGroup>
                                        <ChartGroup>
                                            <ChartWithFilter>
                                                <this.SimpleRowChart title="Author" id="authors" />
                                                <this.SimpleMultiSelectChart
                                                    label="Select Author"
                                                    id="authors"
                                                    stateContext={stateContext}
                                                />
                                            </ChartWithFilter>
                                        </ChartGroup>
                                        <ChartGroup>
                                            <ChartWithFilter>
                                                <this.SimpleRowChart
                                                    title="Co-Author"
                                                    id="authors2"
                                                />
                                                <this.SimpleMultiSelectChart
                                                    label="Select Co-Author"
                                                    id="authors2"
                                                    stateContext={stateContext}
                                                />
                                            </ChartWithFilter>
                                        </ChartGroup>
                                        <ChartGroup>
                                            <ChartWithFilter>
                                                <this.SimpleRowChart
                                                    title="Characteristic"
                                                    id="char"
                                                />
                                                <this.SimpleMultiSelectChart
                                                    label="Select Characteristic"
                                                    id="char"
                                                    stateContext={stateContext}
                                                />
                                            </ChartWithFilter>
                                        </ChartGroup>
                                        <ChartGroup>
                                            <ChartWithFilter>
                                                <this.SimpleRowChart
                                                    title="Intervention"
                                                    id="inter"
                                                />
                                                <this.SimpleMultiSelectChart
                                                    label="Select Intervention"
                                                    id="inter"
                                                    stateContext={stateContext}
                                                />
                                            </ChartWithFilter>
                                        </ChartGroup>
                                        <ChartGroup>
                                            <ChartWithFilter>
                                                <this.SimpleRowChart title="Outcome" id="outcome" />
                                                <this.SimpleMultiSelectChart
                                                    label="Select Outcome"
                                                    id="outcome"
                                                    stateContext={stateContext}
                                                />
                                            </ChartWithFilter>
                                        </ChartGroup>
                                        <ChartGroup>
                                            <ChartWithFilter>
                                                <this.SimpleRowChart title="Journal" id="journal" />
                                                <this.SimpleMultiSelectChart
                                                    label="Select Journal"
                                                    id="journal"
                                                    stateContext={stateContext}
                                                />
                                            </ChartWithFilter>
                                        </ChartGroup>
                                        <ChartGroup>
                                            <this.SimpleRowChart title="License" id="license" />
                                        </ChartGroup>
                                        <ChartGroup>
                                            <this.SimpleRowChart title="Source" id="sourceX" />
                                        </ChartGroup>
                                        <Last />
                                    </Filters>
                                    <Summary>
                                        <DataCount
                                            data={this.getGroup('dataCountData').all()}
                                            itemTypeName="CORD-19 Papers"
                                        />
                                    </Summary>
                                    <div>
                                        {this.state.crossfilterView === View.ANSWER &&
                                        this.state.crossfilterAnswer ? (
                                            <List
                                                size="large"
                                                itemLayout="vertical"
                                                pagination={
                                                    this.getGroup('papers').all().length > 7
                                                        ? { pageSize: 7 }
                                                        : false
                                                }
                                                dataSource={this.getGroup('papers').all()}
                                                renderItem={(paper: Paper) => (
                                                    <Item>
                                                        <PaperSummary paper={paper} />
                                                    </Item>
                                                )}
                                            />
                                        ) : null}

                                        {this.state.crossfilterView === View.LOADING ? (
                                            <Loading message="Loading Papers ..." />
                                        ) : null}

                                        {this.state.crossfilterView === View.ERROR &&
                                        this.state.crossfilterError ? (
                                            <Error
                                                message={`Error loading Papers: ${this.state.crossfilterError}`}
                                            />
                                        ) : null}
                                    </div>
                                </>
                            ) : (
                                <Loading message="Initializing ..." />
                            )}
                        </MainWrapper>
                    );
                }}
            </StateContext.Consumer>
        );
    }

    SimpleRowChart = ({ title, id }: { title: string; id: string }) => (
        <RowChart
            title={title}
            dimension={this.getDim(id + 'Dimension')}
            group={this.getGroup(id + 'Group')}
            initialFilter={this.getInitialRowFilter(id + 'Dimension')}
            groupId={id}
        />
    );

    // todo: fix up filter ('as string[]' below)
    SimpleMultiSelectChart = ({
        label,
        id,
        stateContext
    }: {
        label: string;
        id: string;
        stateContext: StateContextType;
    }) => (
        <MultiSelectChart
            placeholder={label}
            itemTypeName="Papers"
            dimension={this.getDim(id + 'Dimension')}
            group={this.getGroup(id + 'Group')}
            initialFilter={this.getInitialRowFilter(id + 'Dimension') as string[]}
            groupId={id}
            stateContext={stateContext}
        />
    );
}
