import * as React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { Body, Input } from '@allenai/varnish/components';
import { List, AutoComplete, message } from 'antd';
import debounce from 'debounce';

import {
    ChordDiagram,
    PaperSummary,
    TryExamples,
    Loading,
    Error,
    MetaTags,
    Heading,
    Item,
    MainWrapper,
    IntroText
} from '../components';
import {
    getTerms,
    getComentions,
    getMaps,
    MapAnswer,
    MapQuery,
    ComentionQuery,
    ComentionAnswer,
    TermsQuery,
    getPapers,
    PapersQuery,
    PapersAnswer,
    TermsAnswer
} from '../api';

enum View {
    EMPTY,
    LOADING,
    ANSWER,
    ERROR
}

interface Props extends RouteComponentProps {
    datasetId: string;
    description: string;
    seed: string;
}
interface State {
    selectedLinkTermId1?: string;
    selectedLinkTermId2?: string;

    everShownScrollDownMessage?: boolean;

    searchTerm?: string;
    termNotFound?: string;

    autoCompleteTerms: string[];

    mapsQuery: MapQuery;
    mapsView: View;
    mapsAnswer?: MapAnswer;
    mapsError?: string;

    comentionQuery: ComentionQuery;
    comentionView: View;
    comentionAnswer?: ComentionAnswer;
    comentionError?: string;

    papersQuery: PapersQuery;
    papersView: View;
    papersAnswer?: PapersAnswer;
    papersError?: string;
}
export default class Comention extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        const cq = ComentionQuery.fromQueryString(props.location);
        if (!cq.d) {
            cq.d = this.props.datasetId;
        }
        if (!cq.ftm) {
            cq.ftm = this.props.seed;
        }
        if (!cq.l) {
            cq.l = 38;
        }
        if (!cq.ml) {
            cq.ml = cq.l * 2.5;
        }

        this.state = {
            mapsQuery: new MapQuery(this.props.datasetId),
            mapsView: View.EMPTY,
            comentionQuery: cq,
            comentionView: View.EMPTY,
            papersQuery: new PapersQuery(),
            papersView: View.EMPTY,
            autoCompleteTerms: []
        };
    }

    componentDidMount() {
        this.fetchMaps();
    }

    hasAnswerForCurrentQuery() {
        return (
            this.state.comentionAnswer &&
            this.state.comentionAnswer.query.equals(this.state.comentionQuery)
        );
    }

    fetchMaps() {
        const originalMapsQuery = this.state.mapsQuery;
        this.setState({ mapsView: View.LOADING, papersView: View.EMPTY }, () => {
            getMaps(this.state.mapsQuery)
                .then(answer => {
                    if (this.state.mapsQuery.equals(originalMapsQuery)) {
                        this.setState(
                            {
                                mapsView: View.ANSWER,
                                mapsError: undefined,
                                mapsAnswer: answer
                            },
                            () => {
                                if (
                                    this.state.comentionQuery.isValid() &&
                                    !this.hasAnswerForCurrentQuery()
                                ) {
                                    this.fetchComentions();
                                }
                            }
                        );
                    }
                })
                .catch((err: any) => {
                    if (this.state.mapsQuery.equals(originalMapsQuery)) {
                        let error;
                        if (err.response) {
                            if (err.response.data && err.response.data.error) {
                                error = err.response.data.error;
                            } else if (err.response.status === 503) {
                                error =
                                    'Our system is a little overloaded, ' +
                                    'please try again in a moment';
                            }
                        }
                        // Fallback to a general error message
                        if (!error) {
                            error = 'Something went wrong. Please try again.';
                        }
                        this.setState({
                            mapsView: View.ERROR,
                            mapsAnswer: undefined,
                            mapsError: error
                        });
                    }
                });
        });
    }

    fetchComentions() {
        const originalComentionQuery = this.state.comentionQuery;
        this.setState({ comentionView: View.LOADING, papersView: View.EMPTY }, () => {
            getComentions(this.state.comentionQuery)
                .then(answer => {
                    if (this.state.comentionQuery.equals(originalComentionQuery)) {
                        this.setState({
                            comentionView: View.ANSWER,
                            comentionError: undefined,
                            comentionAnswer: answer
                        });
                    }
                })
                .catch((err: any) => {
                    if (this.state.comentionQuery.equals(originalComentionQuery)) {
                        let error;
                        if (err.response) {
                            if (err.response.data && err.response.data.error) {
                                error = err.response.data.error;
                            } else if (err.response.status === 503) {
                                error =
                                    'Our system is a little overloaded, ' +
                                    'please try again in a moment';
                            }
                        }
                        // Fallback to a general error message
                        if (!error) {
                            error = 'Something went wrong. Please try again.';
                        }
                        this.setState({
                            comentionView: View.ERROR,
                            comentionAnswer: undefined,
                            comentionError: error
                        });
                    }
                });
        });
    }

    fetchPapers() {
        const originalPapersQuery = this.state.papersQuery;
        this.setState({ papersView: View.LOADING }, () => {
            getPapers(this.state.papersQuery)
                .then(answer => {
                    if (this.state.papersQuery.equals(originalPapersQuery)) {
                        this.setState({
                            papersView: View.ANSWER,
                            papersError: undefined,
                            papersAnswer: answer
                        });
                    }
                })
                .catch((err: any) => {
                    if (this.state.papersQuery.equals(originalPapersQuery)) {
                        let error;
                        if (err.response) {
                            if (err.response.data && err.response.data.error) {
                                error = err.response.data.error;
                            } else if (err.response.status === 503) {
                                error =
                                    'Our system is a little overloaded, ' +
                                    'please try again in a moment';
                            }
                        }
                        // Fallback to a general error message
                        if (!error) {
                            error = 'Something went wrong. Please try again.';
                        }
                        this.setState({
                            papersView: View.ERROR,
                            papersAnswer: undefined,
                            papersError: error
                        });
                    }
                });
        });
    }

    handelTermClicked = (selectedTermId: string) => {
        const { comentionQuery } = this.state;
        comentionQuery.ftm = selectedTermId;
        this.setState(
            {
                comentionQuery,
                termNotFound: undefined,
                searchTerm: this.idToTerm(selectedTermId)
            },
            () => {
                this.props.history.push(
                    `/${this.props.datasetId}/?${this.state.comentionQuery.toQueryString()}`
                );
                this.fetchComentions();
            }
        );
    };

    handelSearchOnTerm = (termLabel: string) => {
        const termMap = this.state.mapsAnswer ? this.state.mapsAnswer.answer.termsMap : {};
        const keys = Object.keys(termMap).filter(k => {
            return termLabel.localeCompare(termMap[k], 'en', { sensitivity: 'base' }) === 0;
        });
        if (keys.length) {
            this.handelTermClicked(keys[0]);
        } else {
            this.setState({
                termNotFound: termLabel,
                comentionView: View.EMPTY,
                papersView: View.EMPTY
            });
        }
    };

    handelItemSelectLink = (
        selectedLinkTermId1: string,
        selectedLinkTermId2: string,
        paperIds: string
    ) => {
        const papersQuery = new PapersQuery(paperIds);
        this.setState({ selectedLinkTermId1, selectedLinkTermId2, papersQuery }, () => {
            this.fetchPapers();
        });
    };

    handelSearchChange = (searchTerm: any) => {
        this.setState({ searchTerm });
    };

    handelAutoCompleteTerms = (value: string) => {
        getTerms(new TermsQuery(this.props.datasetId, value)).then((resp: TermsAnswer) => {
            if (value === resp.query.ftm) {
                this.setState({ autoCompleteTerms: resp.answer.terms });
            }
        });
    };

    handelAutoCompleteTermsDebounced = debounce(this.handelAutoCompleteTerms, 200);

    papersLoaded = () => {
        this.setState({ everShownScrollDownMessage: true }, () =>
            message.info('Scroll down to see papers')
        );
    };

    destroyPapersLoadedMessage = () => {
        message.destroy();
    };

    idToTerm = (termId?: string) => {
        if (this.state.mapsAnswer && termId) {
            return this.state.mapsAnswer.answer.termsMap[termId];
        }
        return '';
    };

    render() {
        return (
            <MainWrapper>
                <MetaTags
                    title="Co-mention viewer"
                    description="Search for a term to display a network of interrelations. Edges are
                    thicker when terms are co-mentioned more often. Click an edge to show all
                    related papers."
                />
                <IntroText as="div">
                    <Heading>
                        Explore associations between <strong>{this.props.description}</strong>{' '}
                        concepts appearing in the{' '}
                        <a
                            href="https://pages.semanticscholar.org/coronavirus-research"
                            rel="noopener noreferrer"
                            target="_blank">
                            COVID-19 Open Research Dataset
                        </a>
                        .{' '}
                    </Heading>
                    <p>
                        <strong>1. Search for a term or try an example below.</strong>
                        <br />
                        <Body>
                            A network of top related terms mined from the corpus will be displayed.
                        </Body>
                        <br />
                        <strong>2. Hover on a term to see only its co-mentions.</strong>
                        <br />
                        <Body>
                            Edges are thicker when terms are co-mentioned more often in close
                            proximity to each other in papers.
                        </Body>
                        <br />
                        <strong>3. Click an edge and scroll down to see all related papers.</strong>
                        <br />
                        <Body>
                            Links to full papers are included for users who wish to dig deeper.
                        </Body>
                    </p>
                </IntroText>
                {this.state.mapsView === View.ANSWER && this.state.mapsAnswer ? (
                    <>
                        <SearchRow>
                            <AutoComplete
                                placeholder="Enter a term"
                                value={this.state.searchTerm}
                                onChange={this.handelSearchChange}
                                dataSource={this.state.autoCompleteTerms}
                                onSearch={this.handelAutoCompleteTermsDebounced}
                                onSelect={v => {
                                    this.handelSearchOnTerm(v as string);
                                }}>
                                <Input.Search
                                    autoComplete="off"
                                    onSearch={q => {
                                        this.handelSearchOnTerm(q);
                                    }}
                                />
                            </AutoComplete>
                        </SearchRow>
                        <TryExamples
                            examples={Object.keys(this.state.mapsAnswer.answer.covidTermMap).map(
                                i =>
                                    this.state.mapsAnswer
                                        ? {
                                              id: i,
                                              label: this.state.mapsAnswer!.answer.covidTermMap[i]
                                          }
                                        : { id: '', label: '' }
                            )}
                            queryUrl={`/${this.props.datasetId}/?`}
                            datasetId={this.props.datasetId}
                        />

                        <AnswerWrapper>
                            <div>
                                {this.state.termNotFound ? (
                                    <Heading>
                                        No references to "{this.state.termNotFound}" found in the
                                        corpus.
                                    </Heading>
                                ) : null}
                                {this.state.comentionView === View.ANSWER &&
                                this.state.comentionAnswer &&
                                this.state.comentionAnswer.answer.comentions.length ? (
                                    <React.Fragment>
                                        <Heading>
                                            The network of top {this.props.description} associated
                                            with "{this.idToTerm(this.state.comentionQuery.ftm)}" in
                                            the corpus
                                        </Heading>
                                        <ChordDiagram
                                            data={this.state.comentionAnswer.answer.comentions}
                                            dataMaps={this.state.mapsAnswer!.answer}
                                            seedTerm={this.state.comentionAnswer.query.ftm}
                                            onItemSelectTerm={this.handelTermClicked}
                                            onItemSelectLink={this.handelItemSelectLink}
                                        />
                                    </React.Fragment>
                                ) : null}

                                {this.state.comentionView === View.LOADING ? (
                                    <Loading message="Loading data ..." />
                                ) : null}

                                {this.state.comentionView === View.ERROR &&
                                this.state.comentionError ? (
                                    <Error
                                        message={`Error loading data: ${this.state.comentionError}`}
                                    />
                                ) : null}
                            </div>

                            <div>
                                {this.destroyPapersLoadedMessage()}
                                {this.state.papersView === View.ANSWER &&
                                this.state.papersAnswer &&
                                this.state.papersAnswer.answer.papers.length ? (
                                    <React.Fragment>
                                        {!this.state.everShownScrollDownMessage
                                            ? this.papersLoaded()
                                            : null}
                                        <Heading>
                                            Papers with co-references to "
                                            {this.idToTerm(this.state.selectedLinkTermId1)}" and "
                                            {this.idToTerm(this.state.selectedLinkTermId2)}"
                                        </Heading>
                                        <List
                                            size="large"
                                            itemLayout="vertical"
                                            pagination={
                                                this.state.papersAnswer.answer.papers.length > 7
                                                    ? { pageSize: 7 }
                                                    : false
                                            }
                                            dataSource={this.state.papersAnswer.answer.papers}
                                            renderItem={paper => (
                                                <Item>
                                                    <PaperSummary paper={paper} />
                                                </Item>
                                            )}
                                        />
                                    </React.Fragment>
                                ) : null}

                                {this.state.papersView === View.EMPTY ? (
                                    <Heading>
                                        Click on an edge above to see associated papers
                                    </Heading>
                                ) : null}

                                {this.state.papersView === View.LOADING ? (
                                    <Loading message="Loading data ..." />
                                ) : null}

                                {this.state.papersView === View.ERROR && this.state.papersError ? (
                                    <Error
                                        message={`Error loading data: ${this.state.papersError}`}
                                    />
                                ) : null}
                            </div>
                        </AnswerWrapper>
                    </>
                ) : null}
            </MainWrapper>
        );
    }
}

const AnswerWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
`;

const SearchRow = styled.div`
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.md};
`;
