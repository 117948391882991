import React, { useState } from 'react';
import styled from 'styled-components';

import { ComentionQuery } from '../api';

interface Props {
    links: React.ReactNode[];
    maxLinks?: number;
    expanded?: boolean;
    showLessText?: string;
    showMoreText?: string;
}

export const MaxLinks = ({ links, maxLinks, expanded, showLessText, showMoreText }: Props) => {
    const [isExpanded, setIsExpanded] = useState(expanded || false);
    const toggle = (
        <>
            {' '}
            {/* eslint-disable */}
            <a onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded
                    ? showLessText || 'Show Fewer Examples'
                    : showMoreText || 'Show More Examples'}
            </a>
            {/* eslint-enable */}
        </>
    );

    return (
        <span>
            {isExpanded
                ? links.reduce((prev, curr) => [prev, ` |`, curr])
                : links.slice(0, maxLinks).reduce((prev, curr) => [prev, ` |`, curr])}
            {!isExpanded ? '…' : '.'} {toggle}
        </span>
    );
};

export const TryExamples: React.SFC<{
    examples: Array<{ id: string; label: string }>;
    queryUrl: string;
    datasetId: string;
    className?: string;
}> = ({ examples, queryUrl, datasetId, className }) => (
    <TrySuggestions className={className}>
        <label>Try:</label>{' '}
        <MaxLinks
            maxLinks={10}
            links={examples.map<React.ReactNode>(ex => (
                <a
                    key={ex.id}
                    href={`${queryUrl}${new ComentionQuery(datasetId, ex.id).toQueryString()}`}>
                    {ex.label}
                </a>
            ))}
        />
    </TrySuggestions>
);

export const TryExamplesSimple: React.SFC<{
    // todo: make this and above generic
    examples: Array<{ label: string; filter: string }>;
    queryUrl: string;
    className?: string;
    maxLinks?: number;
}> = ({ examples, queryUrl, className, maxLinks }) => (
    <TrySuggestions className={className}>
        <label>Try:</label>{' '}
        <MaxLinks
            maxLinks={maxLinks || 10}
            links={examples.map<React.ReactNode>(ex => (
                <a key={ex.label} href={`${queryUrl}${ex.filter}`}>
                    {ex.label}
                </a>
            ))}
        />
    </TrySuggestions>
);

const TrySuggestions = styled.div`
    margin-top: ${({ theme }) => theme.spacing.xs};
    margin-bottom: ${({ theme }) => theme.spacing.md};
    label {
        font-weight: 700;
        margin-right: ${({ theme }) => theme.spacing.xxs};
    }
    a {
        margin-left: ${({ theme }) => theme.spacing.xxs};
    }
`;
