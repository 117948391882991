export function trackPageView(path: string) {
    // We use `setTimeout` so that the `document.title` is *likely*
    // already updated. This appears to work without issue, but could
    // in theory expose a race condition where the recorded title
    // is that of the previous page.
    setTimeout(() => {
        if (window.gtag && window.googleAnalyticsUA && window.googleAnalyticsUA !== 'DISABLED') {
            window.gtag('config', window.googleAnalyticsUA, {
                page_title: document.title,
                page_path: path
            });
        }
    }, 0);
}

export function trackEvent(category: string, action: string, label?: string) {
    if (window.gtag && window.googleAnalyticsUA && window.googleAnalyticsUA !== 'DISABLED') {
        window.gtag('event', window.googleAnalyticsUA, {
            event_category: category,
            event_action: action,
            event_label: label
        });
    }
}
