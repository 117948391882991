import React from 'react';
import styled from 'styled-components';
import { select, selectAll } from 'd3-selection';
import { curveBundle, lineRadial } from 'd3-shape';
import { hierarchy, cluster, HierarchyNode } from 'd3-hierarchy';
import { ascending } from 'd3-array';
import { DefaultVarnishTheme } from '@allenai/varnish/theme';
import debounce from 'debounce';

import { DataMaps, Comention } from '../api';
import { ellipsify } from './util';

// https://observablehq.com/@d3/hierarchical-edge-bundling

interface Props {
    data?: Comention[];
    dataMaps: DataMaps;
    seedTerm?: string;
    onItemHover?: (label: string) => void;
    onItemSelectTerm?: (termId: string) => void;
    onItemSelectLink?: (termId1: string, termId2: string, paperIds: string) => void;
    className?: string;
}

interface SimpleNode {
    name: string;
    children: Partial<SimpleNode>[];
    refs?: {};
}
type SimpleHierarchyNodeLink = [SimpleHierarchyNode?, SimpleHierarchyNode?];

interface SimpleHierarchyNode extends HierarchyNode<SimpleNode> {
    outgoing?: SimpleHierarchyNodeLink[];
}

interface Palette {
    colorHighlight: string;
    colorout: string;
    colornone: string;
}

export class ChordDiagram extends React.Component<Props> {
    palette: Palette = {
        colorHighlight: DefaultVarnishTheme.color.O8.toString(),
        colorout: DefaultVarnishTheme.color.B7.toString(),
        colornone: DefaultVarnishTheme.color.N4.toString()
    };

    width = 500;
    fontSize = 11;
    radius = this.width / 2;
    line = lineRadial()
        .curve(curveBundle.beta(0.85))
        .radius((d: any) => d.y)
        .angle((d: any) => d.x);

    tree = cluster<SimpleNode>().size([2 * Math.PI, this.radius - 85]);
    node: React.RefObject<SVGSVGElement>;
    maxVisibleRefs = 0;

    constructor(props: Props) {
        super(props);

        this.node = React.createRef();
    }

    componentDidMount() {
        this.createChart(this.props, this.palette);
    }

    componentDidUpdate() {
        this.updateChart();
    }

    createChart = (props: Props, palette: Palette) => {
        const curNode = this.node.current;

        const svg = select(curNode).attr(
            'viewBox',
            `${-this.width / 2}, ${-this.width / 2}, ${this.width}, ${this.width}`
        );

        const getNameFromId = (id: string) => props.data && props.dataMaps.termsMap[id];

        const getSelectDetails = (d: SimpleHierarchyNode) => {
            let ret = props.data && props.dataMaps.termsMap[d.data.name];
            if (d.data.refs) {
                ret += ' :: ' + Object.keys(d.data.refs).join(', ');
            }
            return ret;
        };

        const root = this.tree(
            this.bilink(
                hierarchy<SimpleNode>(this.formatData(props.data)).sort(
                    (a, b) =>
                        ascending(a.height, b.height) ||
                        ascending(getNameFromId(a.data.name), getNameFromId(b.data.name))
                )
            )
        );

        const getLineWidth = (d: any) => {
            // 0.2 -> 12
            return Math.max(
                0.2,
                12 * (d[0].data.refs[d[1].data.name].count / (this.maxVisibleRefs + 1))
            );
        };

        const getLineWidthSelect = (d: any) => {
            // 1 -> 12
            return Math.min(12, Math.max(1, 1.5 * getLineWidth(d)));
        };

        const getLineWidthHover = (d: any) => {
            // 2 -> 18
            return Math.min(18, Math.max(3, 3 * getLineWidth(d)));
        };

        // hover on term
        const mouseoverTerm = (d: SimpleHierarchyNode, _this: any) => {
            // clear color and events on all links
            link.attr('stroke', palette.colornone)
                .attr('cursor', 'default')
                .attr('stroke-width', d => getLineWidth(d))
                .on('click', null)
                .on('mouseover', null)
                .on('mouseout', null);
            // clear all text color
            selectAll('text')
                .attr('fill', 'black')
                .attr('font-weight', 'normal');
            // set this text color to selected
            select(_this)
                .attr('fill', palette.colorHighlight)
                .attr('font-weight', 'bold');
            // for all outgoing links, set color, width, and give interaction events
            selectAll(d.outgoing ? d.outgoing.map((d: any) => d.path) : [])
                .attr('cursor', 'pointer')
                .attr('stroke', palette.colorout)
                .attr('stroke-width', d => getLineWidthSelect(d))
                .on('click', (d: any) => {
                    // pass back event
                    props.onItemSelectLink &&
                        props.onItemSelectLink(
                            d![0]!.data.name,
                            d![1]!.data.name,
                            d![0]!.data.refs[d![1]!.data.name].docIds
                        );
                })
                .on('mouseover', function f(d: any) {
                    // pass back event
                    props.onItemHover &&
                        props.onItemHover(
                            `${getNameFromId(d[0].data.name)} :: ${getNameFromId(d[1].data.name)}`
                        );
                    mouseoverLine(d, this);
                })
                .on('mouseout', function f(_) {
                    props.onItemHover && props.onItemHover('');
                    select(this)
                        .attr('stroke-width', d => getLineWidthSelect(d))
                        .attr('stroke', palette.colorout);
                })
                .raise();
            // color outgoing text
            selectAll(d.outgoing ? d.outgoing.map(([, d]: any) => d.text) : []).attr(
                'fill',
                palette.colorout
            );
        };

        // mouse outside ring, so clear all hovers
        const mouseoverRect = () => {
            // clear color and events on all links
            link.attr('stroke', palette.colornone)
                .attr('stroke-width', d => getLineWidth(d))
                .on('click', null)
                .on('mouseover', null)
                .on('mouseout', null);
            // clear all text color
            selectAll('text')
                .attr('fill', 'black')
                .attr('font-weight', 'normal');
        };

        // hover on line
        const mouseoverLine = (d: any, _this: any) => {
            select(_this)
                .attr('stroke-width', d => getLineWidthHover(d))
                .attr('stroke', palette.colorHighlight);
        };

        const mouseOverDelay = 100;
        const mouseoverTermDebounced = debounce(mouseoverTerm, mouseOverDelay);
        const mouseoverRectDebounced = debounce(mouseoverRect, mouseOverDelay);

        // clearing hover when hover outside of ring
        svg.append('rect')
            .attr('x', -this.width / 2)
            .attr('y', -this.width / 2)
            .attr('width', this.width)
            .attr('height', this.width)
            .attr('fill', 'transparent')
            .on('mouseover', function f(_) {
                mouseoverRectDebounced();
            });
        svg.append('circle')
            .attr('cx', 0)
            .attr('cy', 0)
            .attr('r', this.radius)
            .attr('fill', 'transparent');

        svg.append('g')
            .attr('cursor', 'pointer')
            .attr('font-family', 'sans-serif')
            .attr('font-size', this.fontSize)
            .selectAll('g')
            .data(root.leaves())
            .join('g')
            .attr('transform', d => `rotate(${(d.x * 180) / Math.PI - 90}) translate(${d.y},0)`)
            .append('text')
            .attr('id', d => (d.data.name === props.seedTerm ? 'seed' : null))
            .attr('dy', '.31em')
            .attr('x', d => (d.x < Math.PI ? 6 : -6))
            .attr('text-anchor', d => (d.x < Math.PI ? 'start' : 'end'))
            .attr('transform', d => (d.x >= Math.PI ? 'rotate(180)' : null))
            .text(d => {
                const label = getNameFromId(d.data.name);
                return ellipsify(label, 13);
            })
            .each(function(d: any) {
                d.text = this;
            })
            .on('click', d => props.onItemSelectTerm && props.onItemSelectTerm(d.data.name))
            .on('mouseover', function f(d: SimpleHierarchyNode) {
                // pass back event
                props.onItemHover && props.onItemHover(getSelectDetails(d));
                mouseoverTermDebounced(d, this);
            })
            .on('mouseout', function f(_: SimpleHierarchyNode) {
                // pass back event
                props.onItemHover && props.onItemHover('');
            })
            .call(text => text.append('title').text((d: any) => getNameFromId(d.data.name)));

        // add bounding box around seed term
        const seed = svg.select('#seed');
        const pad = 2;
        svg.append('g')
            .selectAll('seed')
            .data(root.leaves().filter(l => l.data.name === props.seedTerm))
            .join('g')
            .attr('transform', d => `rotate(${(d.x * 180) / Math.PI - 90}) translate(${d.y},0)`)
            .append('rect')
            .attr('x', 6 - pad)
            .attr('y', -(this.fontSize + pad * 2) / 2)
            .attr('width', _ =>
                seed.node() ? (seed.node() as any).getComputedTextLength() * 1.1 + pad * 2 : null
            )
            .attr('height', this.fontSize + pad * 2)
            .attr('stroke', palette.colorHighlight)
            .attr('fill', 'none');

        // set up links
        const link = svg
            .append('g')
            .attr('stroke', palette.colornone)
            .attr('fill', 'none')
            .selectAll('path')
            .data(root.leaves().flatMap((leaf: SimpleHierarchyNode) => leaf.outgoing))
            .join('path')
            .style('mix-blend-mode', 'multiply')
            .attr('d', ([i, o]: any) => this.line(i.path(o)))
            .each(function(d: any) {
                d.path = this;
            })
            .attr('cursor', 'default')
            .attr('stroke-width', d => getLineWidth(d));

        this.updateChart();
    };

    updateChart = () => {};

    /* this shows how to group by type, so leaving till we are done with it
    formatData = (data?: Data2[]) => {
        if (!data) {
            return { name: 'root', children: [] };
        }

        const map = new Map();
        data.forEach((data: Data2) => {
            const { a, b } = data;
            if (a !== b) {
                if (!map.has(a)) {
                    map.set(a, { name: a, refs: {} });
                }
                const va = map.get(a);
                if (!va.refs[b]) {
                    va.refs[b] = 0;
                }
                va.refs[b] = va.refs[b] + 1;
                map.set(a, va);

                if (!map.has(b)) {
                    map.set(b, { name: b, refs: {} });
                }
                const vb = map.get(b);
                if (!vb.refs[a]) {
                    vb.refs[a] = 0;
                }
                vb.refs[a] = vb.refs[a] + 1;
                map.set(b, vb);
            }
        });

        const newMap = new Map<string, Partial<SimpleNode>>();
        Array.from(map.values()).forEach(mv => {
            const newRef: any = {};
            let found = false;
            Object.keys(mv.refs).forEach(k => {
                if (mv.refs[k] > 1) {
                    // only showing if a co-ref exists more then once
                    found = true;
                    newRef[k] = mv.refs[k];
                }
            });
            if (found) {
                newMap.set(mv.name, { name: mv.name, refs: newRef });
            }
        });

        const types = Array.from(new Set(Object.values(data2Types)));
        const childTypes = types.map(t => {
            return {
                name: t,
                children: Array.from(newMap.values()).filter(
                    d => (data2Types as any)[d.name || ''] === t
                )
            };
        });
        const ret = { name: 'root', children: childTypes };
        return ret;
    }; */

    formatData = (comentions?: Comention[]): { name: string; children: any[] } => {
        if (comentions && comentions.length) {
            const map = new Map<number, any>();
            comentions.forEach((com: Comention) => {
                const { termID1, termID2, total, docIDs } = com;
                const a = termID1;
                const b = termID2;
                if (a !== b) {
                    if (!map.has(a)) {
                        map.set(a, { name: a, refs: {} });
                    }
                    const va = map.get(a);
                    if (!va.refs[b]) {
                        va.refs[b] = { count: 0, docIds: '' };
                    }
                    va.refs[b].count += total;
                    va.refs[b].docIds += docIDs;
                    map.set(a, va);

                    if (!map.has(b)) {
                        map.set(b, { name: b, refs: {} });
                    }
                    const vb = map.get(b);
                    if (!vb.refs[a]) {
                        vb.refs[a] = { count: 0, docIds: '' };
                    }
                    vb.refs[a].count += total;
                    vb.refs[a].docIds += docIDs;
                    map.set(b, vb);
                }
            });
            const ret = { name: 'root', children: Array.from(map.values()) };
            return ret;
        }

        return { name: 'root', children: [] };
    };

    bilink = (root: SimpleHierarchyNode) => {
        this.maxVisibleRefs = 0;
        const map = new Map(root.leaves().map(d => [d.data.name.toString(), d]));
        for (const d of root.leaves()) {
            if (d.data.refs) {
                d.outgoing = Object.keys(d.data.refs).map(r => {
                    // hold on to the max number of references on the chart
                    this.maxVisibleRefs = Math.max(
                        this.maxVisibleRefs,
                        (d.data.refs as any)[r].count
                    );
                    return [d, map.get(r)];
                });
            }
        }
        return root;
    };

    render() {
        return (
            <div className={this.props.className}>
                <StyledSvg ref={this.node} />
            </div>
        );
    }
}

const StyledSvg = styled.svg`
    width: 100%;
    height: auto;
    box-sizing: border-box;
`;
