import React from 'react';
import styled from 'styled-components';
import { MaxLengthText } from '@allenai/varnish/components/MaxLengthText';

import { Paper } from '../api';

interface Props {
    paper: Paper;
    disableLink?: boolean;
}

export const PaperSummary = ({ paper }: Props) => {
    const authorNames = paper.authors.join(', ');
    const titleText = !paper.title || paper.title === '' ? 'Unknown Title' : paper.title;
    const s2Url = 'https://api.semanticscholar.org/';
    const url = paper.s2Id
        ? `${s2Url}CorpusID:${paper.s2Id}`
        : paper.doi
        ? `${s2Url}${paper.doi}`
        : paper.arxivId
        ? `${s2Url}arXiv:${paper.arxivId}`
        : paper.pubmedId
        ? `${s2Url}PMID:${paper.pubmedId}`
        : paper.sha
        ? `${s2Url}${paper.sha.split(',')[0]}`
        : paper.magId
        ? `${s2Url}MAG:${paper.magId}`
        : undefined;
    return (
        <>
            <PaperTitle>
                {url ? (
                    <a href={url} rel="noopener noreferrer" target="_blank">
                        {titleText}
                    </a>
                ) : (
                    <span> {titleText} </span>
                )}
            </PaperTitle>

            <div>{authorNames}</div>
            <div>
                {paper.journal ? <strong>{paper.journal} • </strong> : null}
                {paper.publishTime ? <strong>{paper.publishTime} </strong> : null}
            </div>
            <PaperAbstract>
                {!paper.abstract || paper.abstract === '' ? (
                    'No Abstract'
                ) : (
                    <MaxLengthText maxLength={250}>{paper.abstract}</MaxLengthText>
                )}
            </PaperAbstract>
        </>
    );
};

const PaperTitle = styled.h4`
    ${({ theme }) => `
        margin:  0 0 ${theme.spacing.xxs};
    `}
`;

const PaperAbstract = styled.div`
    ${({ theme }) => `
        max-width: 80ch;
        margin: ${theme.spacing.xs} 0 0;
    `}
`;
