/**
 * This is the top-level component that defines your UI application.
 *
 * This is an appropriate spot for application wide components and configuration,
 * stuff like application chrome (headers, footers, navigation, etc), routing
 * (what urls go where), etc.
 *
 * @see https://github.com/reactjs/react-router-tutorial/tree/master/lessons
 */

import * as React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
import {
    Header,
    HeaderColumns,
    Layout,
    Content,
    InternalLink,
    HeaderTitle,
    LeftMenu,
    LeftMenuItem,
    LeftSider,
    Icon,
    IconMenuItemColumns,
    Wrapping,
    BodySmall
} from '@allenai/varnish/components';
import { DefaultLayoutProvider } from '@allenai/varnish/layout';

import { Footer } from './components';
import Comention from './pages/Comention';
import MetadataFacets from './pages/MetadataFacets';
import Clusters from './pages/Clusters';
import About from './pages/About';
import { AppRoute } from './AppRoute';

import coMentionLogo from './icons/co-mention-logo.svg';

/**
 * An array capturing the available routes in your application. You can
 * add or remove routes here.
 * SEEDS: jnlpna:1 (spike), bc5cdr:384 (remdesivir)
 */
const ROUTES: AppRoute[] = [
    {
        path: '/',
        label: 'About',
        component: About,
        icon: 'question-circle'
    },
    {
        path: '/clusters',
        label: 'Network of science',
        component: Clusters,
        componentProps: {
            seed: {
                affiliationsDimension: ['Centers for Disease Control and Prevention']
            }
        },
        icon: 'dot-chart'
    },
    {
        path: '/facets',
        label: 'Faceted search',
        component: MetadataFacets,
        componentProps: { seed: { yearDimension: [[Date.now() - 157766400000, Date.now()]] } },
        icon: 'filter'
    },
    {
        path: '/jnlpba/',
        label: 'Proteins/genes/cells',
        component: Comention,
        componentProps: { datasetId: 'jnlpba', description: 'proteins/genes/cells', seed: '1' },
        icon: 'deployment-unit'
    },
    {
        path: '/bc5cdr/',
        label: 'Diseases/chemicals',
        component: Comention,
        componentProps: { datasetId: 'bc5cdr', description: 'diseases/chemicals', seed: '384' },
        icon: 'gold'
    }
];

interface State {
    menuCollapsed: boolean;
}

export default class App extends React.PureComponent<RouteComponentProps, State> {
    siderWidthExpanded = '224px';
    /**
     * TODO: Figure out why this must be 80px, and no other value, antd
     * sets this explicitly in CSS, so I'm not sure why the collapsedWidth
     * property is provided.
     */
    siderWidthCollapsed = '80px';

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = { menuCollapsed: false };
    }

    handleMenuCollapse = () => {
        this.setState({ menuCollapsed: !this.state.menuCollapsed });
    };

    render() {
        return (
            <BrowserRouter>
                <Route path="/">
                    <DefaultLayoutProvider layoutVariant="app">
                        <Layout bgcolor="white">
                            <Header>
                                <HeaderColumnsWithSpace gridTemplateColumns="auto auto 1fr">
                                    <HeaderInternalLink to="/">
                                        <LogoWithText>
                                            <Logo />
                                            <HeaderTitle>SciSight</HeaderTitle>
                                        </LogoWithText>
                                    </HeaderInternalLink>
                                </HeaderColumnsWithSpace>
                            </Header>
                            <Layout>
                                <LeftSider
                                    width={this.siderWidthExpanded}
                                    collapsedWidth={this.siderWidthCollapsed}
                                    collapsible
                                    collapsed={this.state.menuCollapsed}
                                    onCollapse={this.handleMenuCollapse}>
                                    <LeftMenu defaultSelectedKeys={[this.props.location.pathname]}>
                                        {ROUTES.filter(r => !r.hidden).map(route => (
                                            <LeftMenuItem key={route.path}>
                                                <IconMenuItem
                                                    route={route}
                                                    menuCollapsed={this.state.menuCollapsed}
                                                />
                                            </LeftMenuItem>
                                        ))}
                                    </LeftMenu>
                                </LeftSider>
                                <Layout>
                                    <Content>
                                        <MaxWidthContent>
                                            {ROUTES.map(
                                                ({
                                                    path,
                                                    component: Component,
                                                    componentProps
                                                }) => (
                                                    <Route
                                                        key={path}
                                                        path={path}
                                                        exact
                                                        render={(props: any) => (
                                                            <Component
                                                                {...props}
                                                                {...componentProps}
                                                            />
                                                        )}
                                                    />
                                                )
                                            )}
                                        </MaxWidthContent>
                                    </Content>
                                    <Footer />
                                </Layout>
                            </Layout>
                        </Layout>
                    </DefaultLayoutProvider>
                </Route>
            </BrowserRouter>
        );
    }
}

const HeaderColumnsWithSpace = styled(HeaderColumns)`
    padding: 11.5px 0;
`;

const MaxWidthContent = styled(Content)`
    max-width: 912px;
`;

const LogoWithText = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.md};
`;

const HeaderInternalLink = styled(InternalLink)`
    &&& {
        &,
        &:hover {
            line-height: 54px;
            text-decoration: none;
            color: unset;
        }
    }
`;

const IconMenuItem = ({ route, menuCollapsed }: { route: AppRoute; menuCollapsed: boolean }) => {
    return (
        <InternalLink to={route.path}>
            {!menuCollapsed ? (
                <IconMenuItemColumns>
                    {route.icon ? <Icon type={route.icon} /> : null}
                    <Wrapping>
                        <BodySmall>{route.label}</BodySmall>
                    </Wrapping>
                </IconMenuItemColumns>
            ) : (
                <React.Fragment>
                    {route.icon ? <Icon type={route.icon} /> : null}
                    <span>{route.label}</span>
                </React.Fragment>
            )}
        </InternalLink>
    );
};

const Logo = styled.img.attrs({
    src: coMentionLogo,
    alt: 'SciSight Logo',
    height: '54px'
})``;
