/**
 * This is the main entry point for the UI. You should not need to make any
 * changes here unless you want to update the theme.
 *
 * @see https://github.com/allenai/varnish
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { ThemeProvider } from '@allenai/varnish/theme';
import { ScrollToTopOnPageChange } from '@allenai/varnish/components';
import { createBrowserHistory } from 'history';
import { Helmet } from 'react-helmet';
import { notification } from 'antd';

import App from './App';
import { trackPageView } from './ga';
import { StateContextProvider } from './StateContext';

const history = createBrowserHistory();
history.listen(location => {
    trackPageView(location.pathname);
});

const isChrome = () => {
    // please note,
    // that IE11 now returns undefined again for window.chrome
    // and new Opera 30 outputs true for window.chrome
    // but needs to check if window.opr is not undefined
    // and new IE Edge outputs to true now for window.chrome
    // and if not iOS Chrome check
    // so use the below updated condition
    // see: https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
    var isChromium = (window as any).chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof (window as any).opr !== 'undefined';
    var isIEedge = winNav.userAgent.indexOf('Edge') > -1;
    var isIOSChrome = winNav.userAgent.match('CriOS');
    if (isIOSChrome) {
        // is Google Chrome on IOS
        return true;
    } else if (
        isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false
    ) {
        // is Google Chrome
        return true;
    }
    // not Google Chrome
    return false;
};

ReactDOM.render(
    <BrowserRouter>
        <ScrollToTopOnPageChange />
        <Helmet defaultTitle="SciSight" titleTemplate="SciSight - %s" />
        {!isChrome()
            ? notification.open({
                  message: 'SciSight was developed on Chrome',
                  description: (
                      <span>
                          For the best experience with SciSight, we recommend using the{' '}
                          <a
                              href="https://www.google.com/chrome"
                              rel="noopener noreferrer"
                              target="_blank">
                              Chrome Browser
                          </a>
                          .
                      </span>
                  )
              })
            : null}
        <ThemeProvider>
            <StateContextProvider>
                <Route path="/" component={App} />
            </StateContextProvider>
        </ThemeProvider>
    </BrowserRouter>,
    document.getElementById('root')
);
