import * as React from 'react';
import * as dc from 'dc';
import styled from 'styled-components';

import { StateContextType } from '../StateContext';

interface ResetButtonProps {
    chart: any;
    className?: string;
}

const ResetButton = styled((props: ResetButtonProps) => {
    return (
        <>
            {/* eslint-disable */}
            <a className={`reset ${props.className}`}
                style={{ display: 'none' }}
                onClick={() => {
                    props.chart.filterAll();
                    dc.redrawAll();
                }}>
                    <span>reset </span>
            </a>
             {/* eslint-enable */}
        </>
    );
})`
    span {
        padding: 5px;
        font-size: 11px;
        cursor: pointer;
        margin-left: 5px;
        border-radius: 7px;
        :hover {
            background: ${({ theme }) => theme.color.B2};
        }
    }
`;

interface Props {
    stateContext: StateContextType;
    chartFunction: any;
    className?: string;
    chartProps: any;
}

interface State {
    chart: any;
}

export class ChartTemplateInner extends React.Component<Props, State> {
    div = React.createRef<HTMLDivElement>();
    constructor(props: Props) {
        super(props);

        this.state = {
            chart: null
        };
    }

    componentDidMount() {
        const newChart = this.props.chartFunction(
            this.div.current,
            this.props.chartProps,
            this.props.stateContext
        );

        // store our new chart in the global context
        if (this.props.stateContext && this.props.stateContext.state) {
            const { charts } = this.props.stateContext.state;
            if (charts) {
                charts[this.props.chartProps.groupId] = newChart;
                this.props.stateContext.updateState &&
                    this.props.stateContext.updateState({ charts });
            }
        }

        if (this.props.chartProps.initialFilter) {
            newChart.filter(this.props.chartProps.initialFilter);
        }
        newChart.render();

        this.setState({ chart: newChart });
    }

    render() {
        return (
            <div ref={this.div} className={this.props.className}>
                <label>{this.props.chartProps.title}</label>
                <span className={'filter'} />
                <ResetButton chart={this.state.chart} />
            </div>
        );
    }
}

export const ChartTemplate = styled(ChartTemplateInner)`
    &&& {
        width: 100%;
        height: auto;
        label {
            text-transform: capitalize;
        }

        g.row text {
            fill: ${({ theme }) => theme.color.N10};
        }

        .title,
        .filter {
            margin-left: 20px;
            margin-right: 6px;
        }

        .filter {
            font-size: 10px;
            padding-bottom: 5px;
        }

        .reset-btn {
            display: inline-block;
            padding-bottom: 6px;
        }

        .axis {
            .tick {
                text {
                    /* make axis tick text lighter */
                    fill: ${({ theme }) => theme.color.N6};
                    font-size: 10px;
                    font: 10px sans-serif;
                    /* Makes it so the user can't accidentally click and select text that is meant as a label only */
                    user-select: none;
                    pointer-events: none;
                }

                line {
                    stroke: ${({ theme }) => theme.color.N6};
                    shape-rendering: crispEdges;
                }

                line.grid-line {
                    /* make grid lighter */
                    stroke: ${({ theme }) => theme.color.N3};
                }
            }

            path.domain {
                stroke: ${({ theme }) => theme.color.N6};
                fill: none;
                shape-rendering: crispEdges;
            }
        }
    }
`;
